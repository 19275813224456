import styled from 'styled-components';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import { FileState, setFileState } from '../../store/slices/fileStateSlice';
import { parseTxtFile } from '../../csv';
import routes from '../../routes';

const Dragger = styled(Upload.Dragger)`
  display: block;
  height: calc(100vh - 208px);
`;

const Welcome: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fileState } = useSelector((state: RootState) => state.fileStateStore);

  const props: UploadProps = {
    name: 'file',
    showUploadList: false,
    accept: '.txt,.ok,.bad,.BAD',
    disabled: fileState === FileState.PARSING,
    beforeUpload(file) {
      console.log(file);
      if (file.name.endsWith('.txt') || file.name.endsWith('.ok') || 
          file.name.endsWith('.bad') || file.name.endsWith('.BAD')) {
        dispatch(
          setFileState({
            fileState: FileState.PARSING,
          })
        );
        parseTxtFile(file);
        navigate(routes.sectionsView);
      } else {
        dispatch(
          setFileState({
            fileState: FileState.ERROR,
            error: 'Unable to read selected file',
          })
        );
      }
      return false;
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
        banned files.
      </p>
    </Dragger>
  );
};

export default Welcome;
