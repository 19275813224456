import { convertSectionValue } from '../../convertors/convertors';
import { ClassifiedDescription, ClassifiedsDescription } from '../../types/types';

const classifiedsParser = (classifieds: string[][], sections: string[]) => {
  const result: ClassifiedsDescription = {};
  classifieds.forEach(classified => {
    const classifiedId = classified[0];
    const classifiedSections: ClassifiedDescription = {};
    classified.forEach((value, i) => {
      const sectionId = sections[i];
      if (sectionId && value) {
        classifiedSections[sectionId] = {
          value: convertSectionValue(sectionId, value),
          errors: [],
          warnings: [],
        };
      }
    });
    result[classifiedId] = classifiedSections;
  });

  return result;
};

export { classifiedsParser };
