import { createSlice } from '@reduxjs/toolkit';
import { HeaderDescription } from '../../types/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { closeFile } from '../actions';

type HeaderInitialState = HeaderDescription | null;
type UpdateHeaderPayload = {
  key: keyof HeaderDescription,
  value: string,
};

const headerSlice = createSlice({
  name: 'headerData',
  initialState: null as HeaderInitialState,
  reducers: {
    setHeaderData: (state, action: PayloadAction<HeaderDescription>) => {
      return state = action.payload;
    },
    updateHeaderData: (state, action: PayloadAction<UpdateHeaderPayload>) => {
      if (state) {
        state[action.payload.key] = action.payload.value;
      }
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(closeFile, () => null);
  },
});

export const { setHeaderData, updateHeaderData } = headerSlice.actions;

export default headerSlice.reducer;
