import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { closeFile } from '../actions';
import { ClassifiedDescription, ClassifiedsDescription, SectionDescription } from '../../types/types';
import { runSectionValidators } from '../../validators/validators';

type ClassifiedsInitialState = ClassifiedsDescription;

type AddClassifiedSectionPayload = {
  classifiedId: string,
  sectionId: string,
  value?: string,
};

type UpdateClassifiedSectionPayload = {
  classifiedId: string,
  sectionId: string,
  section: SectionDescription,
};

type RemoveClassfiedSectionPayload = {
  classifiedId: string,
  sectionId: string,
};

type setClassifiedPayload = {
  classifiedId: string,
  classified: ClassifiedDescription,
};

const classifiedsSlice = createSlice({
  name: 'classifiedsData',
  initialState: {} as ClassifiedsInitialState,
  reducers: {
    addClassifiedSection: (state, action: PayloadAction<AddClassifiedSectionPayload>) => {
      const { classifiedId, sectionId, value } = action.payload;
      const next = { ...state };
      const newSection: SectionDescription = {
        value: value ?? '',
        errors: [],
        warnings: [],
      };
      const classified = next[classifiedId];

      const result = runSectionValidators(sectionId, newSection, classified);
      newSection.errors = result.errors;
      newSection.warnings = result.warnings;

      next[classifiedId][sectionId] = newSection;
      return next;
    },
    updateClassifiedSection: (state, action: PayloadAction<UpdateClassifiedSectionPayload>) => {
      const { classifiedId, sectionId, section } = action.payload;
      state[classifiedId][sectionId] = section;
    },
    removeClassifiedSection: (state, action: PayloadAction<RemoveClassfiedSectionPayload>) => {
      const { classifiedId, sectionId } = action.payload;
      delete state[classifiedId][sectionId];
    },
    setClassifieds: (_, action: PayloadAction<ClassifiedsDescription>) => {
      return action.payload;
    },
    setClassified: (state, action: PayloadAction<setClassifiedPayload>) => {
      const {classifiedId, classified} = action.payload;
      state[classifiedId] = classified;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeFile, () => ({}));
  },
});

export const {
  addClassifiedSection,
  updateClassifiedSection,
  removeClassifiedSection,
  setClassifieds,
  setClassified,
} = classifiedsSlice.actions;

export default classifiedsSlice.reducer;
