import styled from 'styled-components';

import UnderDevelopmentComponent from '../../components/layout/UnderDevelopmentComponent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const TextPage = () => {
  // const sections = useSelector((state: RootState) => state.sectionsStore, shallowEqual);
  // const classifieds = useSelector((state: RootState) => state.classifiedsStore, shallowEqual);

  return (
    <Container>
      <UnderDevelopmentComponent title={'Text View Page'} />
    </Container>
  );
};

export default TextPage;
