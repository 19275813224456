import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Layout, Menu, MenuProps, theme } from 'antd';
import {
  FileTextOutlined,
  LayoutOutlined,
  TableOutlined,
  HomeOutlined,
} from '@ant-design/icons';
// TODO: add this button at some point
// import { DataObjectOutlined } from '@mui/icons-material';

import OpenFileButton from '../button/OpenFileButton';
import CreateFileButton from '../button/CreateFileButton';
import CloseFileButton from '../button/CloseFileButton';
import ExportFileButton from '../button/ExportFileButton';
import { FileState } from '../../store/slices/fileStateSlice';
import { RootState } from '../../store/store';
import routes from '../../routes';

const StyledHeader = styled(Layout.Header)<{ $colorBg: string }>`
  display: flex;
  align-items: center;
  background: ${(props) => props.$colorBg};
`;

enum Nav {
  home = 'home',
  sections = 'sections',
  table = 'table',
  text = 'text',
  json = 'json',
  qrcode = 'qrcode',
}

const getItems = (fileIsUploaded: boolean) => [
  {
    label: 'Home',
    routename: routes.index,
    key: Nav.home,
    icon: <HomeOutlined />,
  },
  {
    label: 'Sections View',
    routename: routes.sectionsView,
    key: Nav.sections,
    icon: <LayoutOutlined />,
    disabled: !fileIsUploaded,
  },
  {
    label: 'Table View',
    key: Nav.table,
    routename: routes.tableView,
    icon: <TableOutlined />,
    disabled: !fileIsUploaded,
  },
  {
    label: 'Text View',
    key: Nav.text,
    routename: routes.textView,
    icon: <FileTextOutlined />,
    disabled: !fileIsUploaded,
  },
  // {
  //   label: 'JSON View',
  //   key: Nav.json,
  //   routename: routes.jsonView,
  //   icon: <DataObjectOutlined />,
  //   disabled: !fileIsUploaded,
  // },
];

const Header: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const { fileState } = useSelector((state: RootState) => state.fileStateStore);
  const fileIsUploaded = fileState === FileState.READY;

  const items = getItems(fileIsUploaded);

  const [current, setCurrent] = useState<Nav | null>(Nav.home);

  const onClick: MenuProps['onClick'] = (e: any) => {
    setCurrent(e.key);
    navigate(items.find(i => i.key === e.key)?.routename ?? routes.index);
  };

  useEffect(() => {
    setCurrent(items.find((i) => i.routename === location.pathname)?.key ?? Nav.home);
  }, [items, location.pathname, fileIsUploaded]);

  return (
    <StyledHeader $colorBg={token.colorWhite}>
      <Menu
        style={{ width: '90%' }}
        onClick={onClick}
        selectedKeys={[current as string]}
        mode="horizontal"
        items={items}
      />
      <Flex gap={20}>
        {!fileIsUploaded && (
          <Flex gap={5}>
            <CreateFileButton />
            <OpenFileButton />
          </Flex>
        )}
        {fileIsUploaded && (
          <Flex gap={5}>
            <ExportFileButton />
            <CloseFileButton />
          </Flex>
        )}
      </Flex>
    </StyledHeader>
  );
};

export default Header;
