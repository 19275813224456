import {
  CategoryType,
  SubCategoryType,
  SectionNumberType,
  HeatingType,
  KitchenCode,
  Orientation,
  AvailableAsOf,
  FloodRisk,
  EPC_SCORE
} from '../types/classifiedEnums';
import { SectionsTypeConfig } from '../types/types';

const sectionsConfig: SectionsTypeConfig = {
  [SectionNumberType.LISTING_NUMBER]: {
    'label': '1.Listing number',
    'hint': [
      '- Alphanumeric characters only.',
      '- MAX 8 characters.',
      '- Unique Code of the listing in YOUR system, it means in the database of YOUR system. This number will be transformed during import into our database to make it a unique number from us, but your number will be stored in memory for subsequent updates.  However the number of the property must be unique for each client.',
      '- Should not begin with "0"'
    ],
    'maxLength': 8,
    'uiType': 'textinput'
  },
  [SectionNumberType.ACTION]: {
    'label': '2.Action',
    'hint': 'In case of Erase, one record will delete all prices of an ad',
    'values': [
      { 'value': 'A', 'label': 'Add' },
      { 'value': 'E', 'label': 'Erase' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.CATEGORY]: {
    'label': '3.Category',
    'hint': 'Identification of the ad"s category',
    'values': [
      { 'value': CategoryType.HOUSE, 'label': '1.Houses' },
      { 'value': CategoryType.OFFICE, 'label': '2.Offices' },
      { 'value': CategoryType.INDUSTRY, 'label': '3.Industrial' },
      { 'value': CategoryType.COMMERCIAL, 'label': '4.Commercial' },
      { 'value': CategoryType.APARTMENT, 'label': '5.Apartments' },
      { 'value': CategoryType.LAND, 'label': '6.Ground' },
      { 'value': CategoryType.GARAGE, 'label': '7.Garages' },
      { 'value': CategoryType.OTHER, 'label': '8.Others' },
      { 'value': CategoryType.HOMES_TO_BUILD, 'label': '9.Homes to built' },
      { 'value': CategoryType.HOUSE_GROUP, 'label': '11.Newly-built house' },
      { 'value': CategoryType.APARTMENT_GROUP, 'label': '12.Newly-built apartements' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.PRICE]: {
    'label': '4.Price',
    'hint': 'In Euros',
    'uiType': 'textinput'
  },
  [SectionNumberType.NUMBER_OF_ADRESS]: {
    'label': '6.Number of the adress',
    'hint': 'The box number has to follow the street number, separated by a coma. Box number is mandatory for appartements.',
    'uiType': 'textinput'
  },
  [SectionNumberType.MONTHLY_RENTAL_SALE_PRICE]: {
    'label': '7.Rental/Sale Price',
    'hint': [
      'SALES',
      '_ Selling price, without additional costs, appart from "Public Sales" / Price for Public sales is in SEC 231, 232, 233 and 234.',
      '_ Accepted values: from 2.500 to 35.000.000.',
      '_ For VEV, bouquet is optional, rent (field 183) is mandatory.',
      'For category 9 :',
      '_ For sales of houses to build = "building price from".',
      '_For category 9020, price can be 0',
      '',
      'RENTALS (CAT 2, 3, 4)',
      '_ Monthly rental price in Euros.',
      '_SEC 7 is mandatory only if SEC 224 is blank.',
      '_ If SEC 7 is blank, it will consider the amount from SEC 224 divided by 12.',
      '_ If SEC 7 is completed but SEC 224 is blank, SEC 224 will consider the amount from SEC 7 multiplied by 12.',
      '_Accepted values (CAT 2, 3, 4): from 300 to 12.000.000',
      '_Accepted values (CAT 1, 5, 6, 7): from 25 to 1.000.000'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.ADRESS_WITHOUT_NUMBER]: {
    'label': '8.Adress without number',
    'uiType': 'textinput'
  },
  [SectionNumberType.ZIP_CODE]: {
    'label': '9.ZIP Code',
    'hint': 'If you have properties in a foreign country, country code (field 24) is mandatoryNot mandatory for subcategories 9010 and 9020',
    'uiType': 'textinput'
  },
  [SectionNumberType.LOCALITY]: {
    'label': '10.Locality',
    'hint': 'By default, the locality is given based on the zip code (field 9) and automatically translated. However, if you want to display a different name for the town, the value displayed will be the same for the three languages ​​of the site.',
    'uiType': 'textinput'
  },
  [SectionNumberType.DESCRIPTION_FR]: {
    'label': '11.Description FR',
    'hint': 'These three successive Sections contain, respectively, in French, English and Dutsh, a label of "flash" of good and can hold a maximum of 768 characters. WITH THE STRICT EXCEPTION FOR ANY CHARACTER OF CONTROL (<TAB>, <LF> <RET >, <EOL>, etc ...). This is very important because the application will see these characters as an end of sheet or a transition to a next Section. If your application allows the encoding of items with these control characters, it is imperative the filter when exporting and replace, for example, by a character "Spacing" (ASCII 32). No HTML is allowed in this field.',
    'uiType': 'multilineInput',
    'maxLength': 750,
  },
  [SectionNumberType.DESCRIPTION_EN]: {
    'label': '12.Description EN',
    'hint': 'These three successive Sections contain, respectively, in French, English and Dutsh, a label of "flash" of good and can hold a maximum of 768 characters. WITH THE STRICT EXCEPTION FOR ANY CHARACTER OF CONTROL (<TAB>, <LF> <RET >, <EOL>, etc ...). This is very important because the application will see these characters as an end of sheet or a transition to a next Section. If your application allows the encoding of items with these control characters, it is imperative the filter when exporting and replace, for example, by a character "Spacing" (ASCII 32). No HTML is allowed in this field.',
    'uiType': 'multilineInput',
    'maxLength': 750,
  },
  [SectionNumberType.DESCRIPTION_NL]: {
    'label': '13.Description NL',
    'hint': 'These three successive Sections contain, respectively, in French, English and Dutsh, a label of "flash" of good and can hold a maximum of 768 characters. WITH THE STRICT EXCEPTION FOR ANY CHARACTER OF CONTROL (<TAB>, <LF> <RET >, <EOL>, etc ...). This is very important because the application will see these characters as an end of sheet or a transition to a next Section. If your application allows the encoding of items with these control characters, it is imperative the filter when exporting and replace, for example, by a character "Spacing" (ASCII 32). No HTML is allowed in this field.',
    'uiType': 'multilineInput',
    'maxLength': 750,
  },
  [SectionNumberType.ATTIC_WITH_FIXED_STAIRS]: {
    'label': '14.Attic (accessible with fixed stairs)',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.HEAT_PUMP]: {
    'label': '15.Heat pump',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.PHOTOVOLTAIC_PANNELS]: {
    'label': '16.Photovoltaic pannels',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.SOLAR_PANNELS]: {
    'label': '17.Solar pannels',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.CPEB_CO2_EMISSIONS]: {
    'label': '18.CPEB - CO2 Emissions',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.VAT]: {
    'label': '19.VAT',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.TRANSACTION_TYPE]: {
    'label': '20.Transaction type',
    'values': [
      { 'value': '1', 'label': 'Sales' },
      { 'value': '2', 'label': 'Rent' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.COLLECTIVE_WATER_HEATER]: {
    'label': '21.Common heater and / or water',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.NUMBER_OF_ROOMS]: {
    'label': '22.Number of rooms',
    'hint': 'Determine the number of rooms in France',
    'uiType': 'textinput'
  },
  [SectionNumberType.STATUS_OF_LISTING]: {
    'label': '23.Status of the listing',
    'values': [
      { 'value': '0', 'label': 'Online' },
      { 'value': '1', 'label': 'Deactivated' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.COUNTRY_CODE]: {
    'label': '24.Country code',
    'hint': 'Only if different from "Belgium"',
    'values': [
      { 'value': 'AFS', 'label': 'South Africa' },
      { 'value': 'ALB', 'label': 'Albania' },
      { 'value': 'ALG', 'label': 'Algeria' },
      { 'value': 'D', 'label': 'Germany' },
      { 'value': 'AD', 'label': 'Andorra' },
      { 'value': 'ANN', 'label': 'Netherlands Antilles' },
      { 'value': 'ARG', 'label': 'Argentina' },
      { 'value': 'ARM', 'label': 'Armenia' },
      { 'value': 'AUS', 'label': 'Australia' },
      { 'value': 'A', 'label': 'Austria' },
      { 'value': 'AZE', 'label': 'Azerbaijan' },
      { 'value': 'BGD', 'label': 'Bangladesh' },
      { 'value': 'BER', 'label': 'Belarus' },
      { 'value': 'B', 'label': 'Belgium' },
      { 'value': 'BLZ', 'label': 'Belize' },
      { 'value': 'BOS', 'label': 'Bosnia and Herzegovina' },
      { 'value': 'BWA', 'label': 'Botswana' },
      { 'value': 'BRE', 'label': 'Brazil' },
      { 'value': 'BG', 'label': 'Bulgaria' },
      { 'value': 'KH', 'label': 'Cambodia' },
      { 'value': 'CAN', 'label': 'Canada' },
      { 'value': 'CPV', 'label': 'Cape Verde' },
      { 'value': 'CL', 'label': 'Chile' },
      { 'value': 'CHI', 'label': 'China' },
      { 'value': 'CY', 'label': 'Cyprus' },
      { 'value': 'COR', 'label': 'South Korea' },
      { 'value': 'CRI', 'label': 'Costa Rica' },
      { 'value': 'CIV', 'label': 'Ivory Coast' },
      { 'value': 'HR', 'label': 'Croatia' },
      { 'value': 'DK', 'label': 'Denmark' },
      { 'value': 'EG', 'label': 'Egypt' },
      { 'value': 'ARE', 'label': 'United Arab Emirates' },
      { 'value': 'ESP', 'label': 'Spain' },
      { 'value': 'EE', 'label': 'Estonia' },
      { 'value': 'USA', 'label': 'United States' },
      { 'value': 'FIN', 'label': 'Finland' },
      { 'value': 'F', 'label': 'France' },
      { 'value': 'GEO', 'label': 'Georgia' },
      { 'value': 'GH', 'label': 'Ghana' },
      { 'value': 'GB', 'label': 'United Kingdom' },
      { 'value': 'GR', 'label': 'Greece' },
      { 'value': 'HON', 'label': 'Hong kong' },
      { 'value': 'H', 'label': 'Hungary' },
      { 'value': 'IND', 'label': 'India' },
      { 'value': 'IDN', 'label': 'Indonesia' },
      { 'value': 'EIR', 'label': 'Ireland' },
      { 'value': 'ISR', 'label': 'Israel' },
      { 'value': 'I', 'label': 'Italy' },
      { 'value': 'JAP', 'label': 'Japan' },
      { 'value': 'JOR', 'label': 'Jordan' },
      { 'value': 'KAZ', 'label': 'Kazakhstan' },
      { 'value': 'KEN', 'label': 'Kenya' },
      { 'value': 'KIR', 'label': 'Kyrgyzstan' },
      { 'value': 'LV', 'label': 'Latvia' },
      { 'value': 'LIB', 'label': 'Lebanon' },
      { 'value': 'FL', 'label': 'Liechtenstein' },
      { 'value': 'LT', 'label': 'Lithuania' },
      { 'value': 'L', 'label': 'Luxembourg' },
      { 'value': 'MAC', 'label': 'Macedonia' },
      { 'value': 'MG', 'label': 'Madagascar' },
      { 'value': 'MT', 'label': 'Malta' },
      { 'value': 'MAR', 'label': 'Morocco' },
      { 'value': 'MU', 'label': 'Mauritius' },
      { 'value': 'MX', 'label': 'Mexico' },
      { 'value': 'MOL', 'label': 'Moldova' },
      { 'value': 'MC', 'label': 'Monaco' },
      { 'value': 'MON', 'label': 'Montenegro' },
      { 'value': 'NAM', 'label': 'Namibia' },
      { 'value': 'N', 'label': 'Norway' },
      { 'value': 'OUZ', 'label': 'Uzbekistan' },
      { 'value': 'PA', 'label': 'Panama' },
      { 'value': 'PY', 'label': 'Paraguay' },
      { 'value': 'NL', 'label': 'Netherlands' },
      { 'value': 'PER', 'label': 'Peru' },
      { 'value': 'PHI', 'label': 'Philippines' },
      { 'value': 'PL', 'label': 'Poland' },
      { 'value': 'P', 'label': 'Portugal' },
      { 'value': 'COD', 'label': 'Democratic Republic of the Congo' },
      { 'value': 'DO', 'label': 'Dominican Republic' },
      { 'value': 'CZ', 'label': 'Czechia' },
      { 'value': 'RO', 'label': 'Romania' },
      { 'value': 'RUS', 'label': 'Russia' },
      { 'value': 'RWA', 'label': 'Rwanda' },
      { 'value': 'SN', 'label': 'Senegal' },
      { 'value': 'SER', 'label': 'Serbia' },
      { 'value': 'SGP', 'label': 'Singapore' },
      { 'value': 'SK', 'label': 'Slovakia' },
      { 'value': 'SI', 'label': 'Slovenia' },
      { 'value': 'SE', 'label': 'Sweden' },
      { 'value': 'CH', 'label': 'Switzerland' },
      { 'value': 'TAZ', 'label': 'Tajikistan' },
      { 'value': 'TAI', 'label': 'Taiwan' },
      { 'value': 'TZA', 'label': 'Tanzania' },
      { 'value': 'TH', 'label': 'Thailand' },
      { 'value': 'TUN', 'label': 'Tunisia' },
      { 'value': 'TUR', 'label': 'Turkmenistan' },
      { 'value': 'TR', 'label': 'Turkey' },
      { 'value': 'UKR', 'label': 'Ukraine' },
      { 'value': 'UY', 'label': 'Uruguay' },
      { 'value': 'VE', 'label': 'Venezuela' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.ENERGY_EFFICIENCY_CERTIFICATE_CODE]: {
    'label': '25.UNIQUE CODE "certificat prestation énergétique" (epc)',
    'hint': 'For ads of cat 9, we use SEC 310 "energy efficiency".',
    'uiType': 'textinput'
  },
  [SectionNumberType.AGENT_REFERENCE]: {
    'label': '27.Agent reference',
    'hint': 'Free reference of the ad, particular for the agent/agency',
    'uiType': 'textinput',
    'maxLength': 20
  },
  [SectionNumberType.AVAILABILITY_DATE]: {
    'label': '28.Availability date',
    'hint': 'Date format YYYYMMDD',
    'uiType': 'textinput'
  },
  [SectionNumberType.SUBCATEGORY]: {
    'label': '30.Subcategory',
    'hint': 'Subcategory code on 4 positions, linked to the category of the ad.  The first position has to match the ad category (SEC. 3).',
    'values': [
      { 'value': SubCategoryType.BUNGALOW, 'label': '1010.Bungalow' },
      { 'value': SubCategoryType.CHALET, 'label': '1011.Chalet' },
      { 'value': SubCategoryType.CASTLE, 'label': '1012.Chateau' },
      { 'value': SubCategoryType.FARMHOUSE, 'label': '1014.Farmhouse' },
      { 'value': SubCategoryType.COUNTRY_COTTAGE, 'label': '1015.Country cottage' },
      { 'value': SubCategoryType.EXCEPTIONAL_PROPERTY, 'label': '1018.Exceptional property' },
      { 'value': SubCategoryType.APARTMENT_BLOCK, 'label': '1020.Apartment block' },
      { 'value': SubCategoryType.MIXED_USE_BUILDING, 'label': '1021.Mixed-use building' },
      { 'value': SubCategoryType.HOUSE, 'label': '1024.House' },
      { 'value': SubCategoryType.TOWN_HOUSE, 'label': '1025.Town house' },
      { 'value': SubCategoryType.MANSION, 'label': '1026.Mansion' },
      { 'value': SubCategoryType.VILLA, 'label': '1037.Villa' },
      { 'value': SubCategoryType.OTHER_PROPERTY, 'label': '1040.Other property' },
      { 'value': SubCategoryType.MANOR_HOUSE, 'label': '1041.Farmhouse' },
      { 'value': SubCategoryType.PAVILION, 'label': '1051.Pavilion' },
      { 'value': SubCategoryType.HOUSE_GROUP, 'label': '1101.New house' },
      { 'value': SubCategoryType.APARTMENT_GROUP, 'label': '1201.New apartement' },
      { 'value': SubCategoryType.OFFICES, 'label': '2001.Offices' },
      { 'value': SubCategoryType.BUILDING, 'label': '2002.Building' },
      { 'value': SubCategoryType.OFFICE_BLOCK, 'label': '2003.Office block' },
      { 'value': SubCategoryType.MIXED_USE_BUILDING_OFFICES, 'label': '2004.Mixed-use building' },
      { 'value': SubCategoryType.LARGE_TOWN_HOUSE, 'label': '2006.Large town house' },
      { 'value': SubCategoryType.COMMERCIAL_VILLA, 'label': '2007.Villa' },
      { 'value': SubCategoryType.INDUSTRIAL_PREMISES, 'label': '3001.Industrial premises' },
      { 'value': SubCategoryType.MIXED_USE_BUILDING_INDUSTRIES, 'label': '3002.Mixed-use building' },
      { 'value': SubCategoryType.WAREHOUSE, 'label': '3003.Warehouse' },
      { 'value': SubCategoryType.BUSINESS, 'label': '4001.Business' },
      { 'value': SubCategoryType.COMMERCIAL_PREMISES, 'label': '4002.Building for business use' },
      { 'value': SubCategoryType.MIXED_USE_BUILDING_COMMERCIAL, 'label': '4003.Mixed-use building' },
      { 'value': SubCategoryType.HOTEL_RESTAURANT_CAFE, 'label': '4004.Restaurant' },
      { 'value': SubCategoryType.APARTMENT, 'label': '5001.Apartments' },
      { 'value': SubCategoryType.GROUND_FLOOR, 'label': '5002.Groud Floor' },
      { 'value': SubCategoryType.DUPLEX, 'label': '5003.Two-floor apartment' },
      { 'value': SubCategoryType.TRIPLEX, 'label': '5004.Three-floor apartment' },
      { 'value': SubCategoryType.FLAT_STUDIO, 'label': '5005.Flat/Studio' },
      { 'value': SubCategoryType.PENTHOUSE, 'label': '5006.Penthouse' },
      { 'value': SubCategoryType.LOFT, 'label': '5007.Loft/Entrepôt' },
      { 'value': SubCategoryType.KOT, 'label': '5008.Kot' },
      { 'value': SubCategoryType.SERVICE_FLAT, 'label': '5009.Service Flat' },
      { 'value': SubCategoryType.BUILDING_LAND, 'label': '6001.Building land' },
      { 'value': SubCategoryType.RECREATIONAL_AREA, 'label': '6002.Recreational area' },
      { 'value': SubCategoryType.FOREST, 'label': '6003.Forest' },
      { 'value': SubCategoryType.FIELDS, 'label': '6004.Fields' },
      { 'value': SubCategoryType.MEADOW, 'label': '6005.Meadow' },
      { 'value': SubCategoryType.ORCHARD, 'label': '6006.Orchard' },
      { 'value': SubCategoryType.UNBUILDABLE_LAND, 'label': '6007.Unbuildable land' },
      { 'value': SubCategoryType.AGRICULTURAL_AREA, 'label': '6008.Agricultural land' },
      { 'value': SubCategoryType.LAND, 'label': '6009.Land' },
      { 'value': SubCategoryType.OUTDOOR_PARKING_SPACE, 'label': '7001.Outdoor parking space' },
      { 'value': SubCategoryType.COVERED_PARKING_SPACE, 'label': '7002.Covered parking space' },
      { 'value': SubCategoryType.LOCK_UP_GARAGE, 'label': '7003.Lock-up garage' },
      { 'value': SubCategoryType.LOCK_UP_PARKING, 'label': '7004.Close parking' },
      { 'value': SubCategoryType.OTHER, 'label': '8000.Other' },
      { 'value': SubCategoryType.PROVINCIAL_FARMHOUSE, 'label': '8001.Provencal farmhouse' },
      { 'value': SubCategoryType.MILL, 'label': '8002.Mill' },
      { 'value': SubCategoryType.BED_N_BREAKFAST, 'label': '8003.Bed & breakfast' },
      { 'value': SubCategoryType.GITES, 'label': '8004.Gîtes' },
      { 'value': SubCategoryType.STATIC_CARAVAN, 'label': '8005.Static caravan' },
      { 'value': SubCategoryType.MOBILE_HOME, 'label': '8006.Mobile home' },
      { 'value': SubCategoryType.CAMPSITE, 'label': '8007.Campsite' },
      { 'value': SubCategoryType.HOLIDAY_PARK, 'label': '8008.Holiday park' },
      { 'value': SubCategoryType.BARGE, 'label': '8009.Barge' },
      { 'value': SubCategoryType.HOTEL, 'label': '8010.Hotel' },
      { 'value': SubCategoryType.OTHER_HOUSE, 'label': '8011.House' },
      { 'value': SubCategoryType.BOAT_CRUISE, 'label': '8012.Boat cruise' },
      { 'value': SubCategoryType.MODEL_HOUSE, 'label': '9010.Model House' },
      { 'value': SubCategoryType.REFERENCE, 'label': '9020.Reference' },
      { 'value': SubCategoryType.SHOW_HOUSE, 'label': '9030.Show House' }
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.ENVIRONMENT]: {
    'label': '31.Environment',
    'values': [
      { 'value': 'US', 'label': 'Urban' },
      { 'value': 'RR', 'label': 'Residential' },
      { 'value': 'CP', 'label': 'Country' },
      { 'value': 'AM', 'label': 'Fitted out' },
      { 'value': 'BB', 'label': 'Concrete' },
      { 'value': 'CC', 'label': 'Mall' },
      { 'value': 'CL', 'label': 'Compartmentalized' },
      { 'value': 'IS', 'label': 'Isolated' },
      { 'value': 'NA', 'label': 'Not fitted out' },
      { 'value': 'PA', 'label': 'Landscape' },
      { 'value': 'RC', 'label': 'Shop Street' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.CADASTRAL_INCOME]: {
    'label': '32.Cadastral Income',
    'hint': 'Ammount in Euro',
    'uiType': 'textinput'
  },
  [SectionNumberType.TOTAL_FLOOR_AREA]: {
    'label': '33.Total floor area of the property',
    'hint': [
      '- Value in square meters, Accept decimals.  (min = 5; max =20.000)',
      '- Becomes "total area" for ads of category 2, 3, 4.'
    ],
    'uiType': 'numberInput',
    'minValue': 5,
    'maxValue': 20000
  },
  [SectionNumberType.YEAR_OF_CONSTRUCTION]: {
    'label': '34.Year of construction',
    'hint': 'Must be > 1770 Must be 4 digits',
    'uiType': 'numberInput',
    'maxLength': 4,
    'minValue': 1770,
  },
  [SectionNumberType.TOTAL_GROUND_AREA]: {
    'label': '36.Total ground aera of the property',
    'hint': 'Aera in square meter of the field of the property',
    'uiType': 'textinput'
  },
  [SectionNumberType.FRONT_FIELD]: {
    'label': '37.Front field',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.BACK_FIELD]: {
    'label': '38.Back field',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.FLAT_FIELD]: {
    'label': '39.Flat field',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.LAND_WIDTH]: {
    'label': '43.Land width',
    'hint': 'Value in meters',
    'uiType': 'textinput'
  },
  [SectionNumberType.LAND_DEPTH]: {
    'label': '44.Land depth',
    'hint': 'Value in meters',
    'uiType': 'textinput'
  },
  [SectionNumberType.WOODEN_LAND]: {
    'label': '45.Wooden land',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.BUILDING_PERMIT]: {
    'label': '46.Building permit',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
      { 'value': 'not communicated', 'label': 'Not communicated' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.SEWER_CONNECTION]: {
    'label': '47.Sewer connection',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.ELECTRICITY_CONNECTION_POSSIBILITY]: {
    'label': '48.Possibility of connecting electricity',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.CONNECTION_TO_ELECTRICITY_GAS_WATER]: {
    'label': '49.Connection to electricity/Gas/Water',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.TYPE_OF_BUILDING]: {
    'label': '50.Type of Building',
    'hint': 'For ads of category 6.',
    'values': [
      { 'value': 'BE', 'label': 'Bel Etage' },
      { 'value': 'BG', 'label': 'Bungalow' },
      { 'value': 'IA', 'label': 'Appartement Building' },
      { 'value': 'M', 'label': 'House' },
      { 'value': 'TT', 'label': 'All kind' },
      { 'value': 'VI', 'label': 'Villa' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.TOTAL_CONSTRUCTION_SURFACE]: {
    'label': '51.Total construction surface (ground)',
    'hint': [
      '_ For lands and houses to build',
      '_ Part of the surface on which it is possible to build, in square meters (accept decimal)',
      '_ Displayed as "total ground surface" for houses to build (cat 9).'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.NUMBER_OF_FLOORS]: {
    'label': '52.Number of floors',
    'hint': [
      'Or the number of authorized levels by urbanism on a land to build',
      'Or the number of levels of a house',
      'Or the number of levels of the building in wich the appartement is located'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.OBLIGATION_TOBUILD]: {
    'label': '53.Obligation to build',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.NUMBER_OF_HOUSINGS]: {
    'label': '54.Number of housings',
    'hint': 'Number of housings in an appartement building, investment property or house to renovate.',
    'uiType': 'textinput'
  },
  [SectionNumberType.PROPERTY_CONDITION]: {
    'label': '55.Condition (state) of the property',
    'values': [
      { 'value': 'B', 'label': 'Good condition' },
      { 'value': 'E', 'label': 'As new' },
      { 'value': 'H', 'label': 'To be restored' },
      { 'value': 'N', 'label': 'Just renovated' },
      { 'value': 'R', 'label': 'To be done up' },
      { 'value': 'Y', 'label': 'To be renovated' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.FURNISHED]: {
    'label': '56.furnished',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.FACADE_WIDTH]: {
    'label': '57.Full facade width',
    'uiType': 'numberInput',
  },
  [SectionNumberType.FACADE_COUNT]: {
    'label': '58.Number of facade',
    'uiType': 'numberInput',
  },
  [SectionNumberType.FLOOR_NUMBER]: {
    'label': '59.Floor number',
    'hint': 'Describes at wich floor is the property located',
    'uiType': 'numberInput',
  },
  [SectionNumberType.GARDEN_SURFACE]: {
    'label': '60.Garden surface',
    'hint': 'Value in square meters(accept decimals)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.HEATING_TYPE]: {
    'label': '61.Type of heating',
    'values': [
      { 'value': HeatingType.MAZOUT, 'label': 'Mazout / Mazout / Fuel' },
      { 'value': HeatingType.GAZ, 'label': 'Gaz / Aardgaz / Gaz' },
      { 'value': HeatingType.ELECTRIC, 'label': 'Electrique / Elektrisch / Electric' },
      { 'value': HeatingType.CHARBON, 'label': 'Charbon / Kolen / Charbon' },
      { 'value': HeatingType.PELLET, 'label': 'Pellet / balletje / Pellet' },
      { 'value': HeatingType.WOOD, 'label': 'Bois / Hout / Wood' }
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.DOUBLE_GLAZING]: {
    'label': '62.Double glazing',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.LIVING_ROOM_SURAFACE]: {
    'label': '65.Living room surface',
    'hint': 'Value in square meters(accept decimals)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.DINING_ROOM_SURAFACE]: {
    'label': '66.Dining room surface',
    'hint': 'Value in square meters(accept decimals)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.OFFICE_SURAFACE]: {
    'label': '67.Office surface',
    'hint': 'Value in square meters(accept decimals)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.FIREPLACE_NUMBER]: {
    'label': '68.Fireplace count',
    'uiType': 'numberInput',
  },
  [SectionNumberType.KITCHEN_SURFACE]: {
    'label': '69.Kitchen surface',
    'hint': 'Value in square meters(accept decimals)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.KITCHEN_CODE]: {
    'label': '61.Type of heating',
    'values': [
      { 'value': KitchenCode.INSTALLED, 'label': 'équipée / Geïnstalleerd / Installed' },
      { 'value': KitchenCode.SEMI_EQUIPED, 'label': 'semi-équipée / Gedeel.Geïnstalleerd / Semi-equiped' },
      { 'value': KitchenCode.UNISTALLED, 'label': 'non-équipée / Niet Geïnstalleerd / Uninstalled' },
      { 'value': KitchenCode.HYPER_EQUIPED, 'label': 'hyper-équipée / Hyper-geïnstalleerd / Hyper-equiped' },
      { 'value': KitchenCode.USA_SEMI_EQUIPED, 'label': 'Américaine semi-équipée / Amerikaanse gedeel. geïnstalleerd / USA Semi-equiped' },
      { 'value': KitchenCode.USA_INSTALLED, 'label': 'Américaine équipée / Amerikaanse geïnstalleerd / USA Installed' },
      { 'value': KitchenCode.USA_HYPER_EQUIPED, 'label': 'Américaine hyper-équipée / Amerikaanse hyper-geïnstalleerd / USA Hyper-equiped' },
      { 'value': KitchenCode.USA_UNISTALLED, 'label': 'Américaine Non-équipée / Amerikaanse Niet Geïnstalleerd / USA Uninstalled' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.LAUNDRY_ROOM]: {
    'label': '71.Laundry Room',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.NUMBER_OF_BEDROOMS]: {
    'label': '73.Number of bedrooms',
    'hint': 'Not editable',
    'uiType': 'numberInput',
  },
  [SectionNumberType.BEDROOM_SURFACE_1]: {
    'label': '74.Bedroom surface 1',
    'hint': 'Value in square meters (accept decimals)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.BEDROOM_SURFACE_2]: {
    'label': '75.Bedroom surface 2',
    'hint': 'Value in square meters (accept decimals)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.BEDROOM_SURFACE_3]: {
    'label': '76.Bedroom surface 3',
    'hint': 'Value in square meters (accept decimals)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.BEDROOM_SURFACE_4]: {
    'label': '77.Bedroom surface 4',
    'hint': 'Value in square meters (accept decimals)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.BEDROOM_SURFACE_5]: {
    'label': '78.Bedroom surface 5',
    'hint': 'Value in square meters (accept decimals)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.NUMBER_OF_BATHROOMS]: {
    'label': '79.Number of bathrooms',
    'uiType': 'numberInput',
  },
  [SectionNumberType.NUMBER_OF_SHOWERS]: {
    'label': '80.Number of showers',
    'uiType': 'numberInput',
  },
  [SectionNumberType.NUMBER_OF_TOILETS]: {
    'label': '81.Number of toilets',
    'uiType': 'numberInput',
  },
  [SectionNumberType.ATTIC_SURFACE_NOT_CONVERTED]: {
    'label': '82.Surface attic non-converted',
    'hint': 'Surface in square meters.  On the site, the sum of SEC 82 et 83 is displayed.',
    'uiType': 'numberInput',
  },
  [SectionNumberType.ATTIC_SURFACE_CONVERTED]: {
    'label': '83.Surface attic converted',
    'hint': 'Surface in square meters.  On the site, the sum of SEC 82 et 83 is displayed.',
    'uiType': 'numberInput',
  },
  // TODO 85 - document problems
  [SectionNumberType.BASEMENT_SURFACE]: {
    'label': '86.Surface cave',
    'hint': 'Value in square meters. For industries, businesses and offices, it is the surface of premises extensions',
    'uiType': 'numberInput',
  },
  [SectionNumberType.TERRASE_SURFACE]: {
    'label': '87.Terrace surface',
    'hint': 'Value in square meters. (max 500 m²).  Comas (",") are allowed but will not be displayed: only plain numbers will be displayed.',
    'uiType': 'numberInput',
  },
  // TODO 88 is missing in documentation
  [SectionNumberType.NUMBER_OF_CLOSED_BOXES]: {
    'label': '89.Number of closed boxes',
    'hint': 'Number of closed boxes (89), inside garages (90 ans 219) are summed',
    'uiType': 'numberInput',
  },
  [SectionNumberType.NUMBER_OF_INSIDE_GARAGE]: {
    'label': '90.Number of inside garages',
    'hint': 'Number of closed boxes (89), inside garages (90 ans 219) are summed',
    'uiType': 'numberInput',
  },
  [SectionNumberType.NUMBER_OF_OUTSIDE_PLACES]: {
    'label': '91.Number of outside places',
    'hint': 'Number of outside places (91) and number of individual parkings (220) are summed',
    'uiType': 'numberInput',
  },
  [SectionNumberType.DOOR_PHONE]: {
    'label': '92.Door phone',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.ALARM]: {
    'label': '93.Alarm',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.CONCIERGE]: {
    'label': '94.Concierge',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.ELEVATOR]: {
    'label': '95.Elevator',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.ARMOURED_DOOR]: {
    'label': '96.Armoured door',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.TELE_DISTRIBUTION]: {
    'label': '97.Teledistribution',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.ACCESS_FOR_DISABLED]: {
    'label': '98.Access for disabled',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.DISTANCE_CLOSE_BUS_STOP]: {
    'label': '99.Distance to closest bus stop',
    'hint': 'Value in meters (no decimal)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.DISTANCE_CLOSE_TRAM_STOP]: {
    'label': '100.Distance to closest tram stop',
    'hint': 'Value in meters (no decimal)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.DISTANCE_CLOSE_SUBWAY]: {
    'label': '101.Distance to closest subway',
    'hint': 'Value in meters (no decimal)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.DISTANCE_CLOSE_TRAIN_STATION]: {
    'label': '102.Distance to closest train station',
    'hint': 'Value in meters (no decimal)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.DISTANCE_CLOSE_SCHOOL]: {
    'label': '103.Distance to closest school',
    'hint': 'Value in meters (no decimal)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.DISTANCE_CLOSE_SHOP]: {
    'label': '104.Distance to closest shop',
    'hint': 'Value in meters (no decimal)',
    'uiType': 'numberInput',
  },
  [SectionNumberType.MONTHLY_COST]: {
    'label': '108.Monthly charges',
    'hint': 'In Euros',
    'uiType': 'numberInput',
  },
  [SectionNumberType.GARDEN_ORIENTATION]: {
    'label': '109.Garden orientation',
    'hint': 'For cat 6 (Fields), orientation when looking at the façade from the street.',
    'values': [
      { 'value': Orientation.NORTH, 'label': 'Nord / Noord / North' },
      { 'value': Orientation.SOUTH, 'label': 'Sud / Zuid / South' },
      { 'value': Orientation.EAST, 'label': 'Est / Oost / East' },
      { 'value': Orientation.NORTH_EAST, 'label': 'Nord-Est / Noordoost / Northeast' },
      { 'value': Orientation.SOUTH_EAST, 'label': 'Sud-Est / Zuidoost / Southeast' },
      { 'value': Orientation.WEST, 'label': 'Ouest / West / West' },
      { 'value': Orientation.NORTH_WEST, 'label': 'Nord-Ouest / Noord-West / North-West' },
      { 'value': Orientation.SOUTH_WEST, 'label': 'Sud-Ouest / Zuid-West / South-West' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.NEW_CONSTRUCTION]: {
    'label': '116.New construction',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.INVESTMENT_PROPERTY]: {
    'label': '117.Investment property',
    'hint': 'Is considered as investment property a building having several units destinated to be rented. Authorized only for sale',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.HOLIDAY_RENTAL]: {
    'label': '118.Holiday rental',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.CERTIFICATE_OIL_TANK]: {
    'label': '119.Certificate: Oil tank',
    'values': [
      { 'value': 'Y', 'label': 'Oui, conforme / Ja, geldig / Yes, conform' },
      { 'value': 'W', 'label': 'Oui, non-conforme / Ja, niet-geldig / yes, not-conform' },
      { 'value': 'N', 'label': 'Non / Neen / No' },
      { 'value': '', 'label': 'Non-communiqué/vierge / Niet meegedeeld/leeg / Not communicated/empty' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.CERTIFICATE_ELECTRIC_INSTALLATION]: {
    'label': '119.Certificate: Electric installation',
    'values': [
      { 'value': 'Y', 'label': 'Oui, conforme / Ja, geldig / Yes, conform' },
      { 'value': 'W', 'label': 'Oui, non-conforme / Ja, niet-geldig / yes, not-conform' },
      { 'value': 'N', 'label': 'Non / Neen / No' },
      { 'value': '', 'label': 'Non-communiqué/vierge / Niet meegedeeld/leeg / Not communicated/empty' }
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.OCCUPANCY_RATE_PERCENTAGE]: {
    'label': '121.Percentage rented',
    'hint': 'Value in % .',
    'uiType': 'numberInput',
  },
  [SectionNumberType.MONTHLY_INCOME]: {
    'label': '122.Monthly income',
    'hint': 'Value in Euros',
    'uiType': 'numberInput',
  },
  [SectionNumberType.TITLE_FR]: {
    'label': '123.Title FR',
    'hint': 'Tilte of the listing FR (max 60car)',
    'uiType': 'textinput'
  },
  [SectionNumberType.TITLE_NL]: {
    'label': '124.Title NL',
    'hint': 'Tilte of the listing NL (max 60car)',
    'uiType': 'textinput'
  },
  [SectionNumberType.TITLE_EN]: {
    'label': '125.Title EN',
    'hint': 'Tilte of the listing EN (max 60car)',
    'uiType': 'textinput'
  },
  [SectionNumberType.ENERGETIC_PERFORMANCE]: {
    'label': '126.Energetic Performance (epc)',
    'hint': [
      'Whole number in Kwh',
      'This number make the EPC graphic appear on the "energy" tab of an ad.'
    ],
    'uiType': 'numberInput',
  },
  [SectionNumberType.FORMAT_L]: {
    'label': '127.Dressing room',
    'hint': [
      '. Determine if the Ad benefit of a visibility option "L"',
      '. Option "L" is deactivated by "XL" or "In the spotlight" if present.',
    ],
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.FORMAT_XL]: {
    'label': '128.Dressing room',
    'hint': [
      '. Determine if the Ad benefit of a visibility option "XL"',
      '. Option "XL" is deactivated by "In the spotlight" if present.'
    ],
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.CERTIFICATE_AS_BUILT]: {
    'label': '132.Certificate "AS BUILT"',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.HAS_JACCUZI]: {
    'label': '133.Has jaccuzi',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.HAS_SAUNA]: {
    'label': '134.Has sauna',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.ANNEX_COUNT]: {
    'label': '135.Annex count',
    'uiType': 'numberInput',
  },
  [SectionNumberType.E_LEVEL]: {
    'label': '136.E level',
    'hint': [
      'level of energy efficiency for a housing.',
      'From 1 to max 999'
    ],
    'uiType': 'numberInput',
  },
  [SectionNumberType.K_LEVEL]: {
    'label': '137.K Level',
    'hint': [
      'Level of thermal insulation for a housing.',
      'From 1 to max 999'
    ],
    'uiType': 'numberInput',
  },
  [SectionNumberType.CBEP_DESCRIPTION_FR]: {
    'label': '138.CBEP label FR',
    'hint': [
      'Text to be filled in with the different informations related to the energetic performance of each unit of the investment property.',
      'Text (max 750 caract.)'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.CBEP_DESCRIPTION_NL]: {
    'label': '139.CBEP label NL',
    'hint': [
      'Text to be filled in with the different informations related to the energetic performance of each unit of the investment property.',
      'Text (max 750 caract.)'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.CBEP_DESCRIPTION_EN]: {
    'label': '140.CBEP label EN',
    'hint': [
      'Text to be filled in with the different informations related to the energetic performance of each unit of the investment property.',
      'Text (max 750 caract.)'
    ],
    'uiType': 'textinput'
  },

  [SectionNumberType.ATTACHED_PDF_FILES]: {
    'label': '145.Attached Pdf files',
    'hint': [
      'Requirements specification or plans on PDF format. You can attach several files. Files name are separated by comas. Only available for ads of category > 8.  Name of the file must be maximum 114 caracters',
      'Extension.pdf. Max 5 files.',
      'In order to delete a file, you must send the name of the file in this field with the extension ".del"',
      'Example: "Name_FileA.del"'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.PROMOTION_NAME]: {
    'label': '146.Name of the promotion',
    'hint': 'Max 25 caract.',
    'uiType': 'textinput'
  },
  [SectionNumberType.PROMOTION_UNIT_COUNT]: {
    'label': '147.Promotion number of housing units',
    'hint': [
      'Minimum 2',
      'Number of individual ads linked t the promotion',
      'That number can NOT be modified after. Once the maximum number is reached, no more units can be added.'
    ],
    'uiType': 'numberInput',
  },
  [SectionNumberType.PRE_EMPTIVE_RIGHT]: {
    'label': '148.Pre-emptive right possible',
    'hint': 'By default: "not communicated"',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.SUB_DIVISION_AUTHORIZATION]: {
    'label': '149.Subdivision authorization',
    'hint': 'By default: "not communicated"',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.LAST_USE_DESIGNATION]: {
    'label': '150.Last use designation',
    'hint': 'By default: "not communicated"',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.PROMOTION_PROJECT_ID]: {
    'label': '151.Client number of the promotion',
    'hint': [
      'Mandatory for ads being part of a promotion',
      'Number created previously, at the creation of the grouped ad (= SEC 1 of the project).',
      'This value is necessary to link the individual ad to the promotion.'
    ],
    'uiType': 'numberInput',
  },
  [SectionNumberType.PHASE_CODE]: {
    'label': '152.Code of phase',
    'hint': [
      'Determines the phase of the building process.',
      'Can be applied at level of the Project or at level of the Individual Unit',
      '!!!! Always MANDATORY for the "Invidual Units"  !!!',
      '- Values 1 to 36 concern the Invidual Units (linked to a project)',
      '- Values > 36 concern the Project itself (category 11 and 12).'
    ],
    'uiType': 'numberInput',
  },
  //153
  [SectionNumberType.IS_BREACHING_URBAN_PLANNING_REGULATION]: {
    'label': '154.Is breaching Urban planning regulation',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.ESTIMATED_VALUE_OF_PROPERTY]: {
    'label': '155.Estimated value of the property',
    'hint': [
      'between 2500 and 35000000',
      'Only for annuality sales.',
      'Amount in Euros.',
      'Not displayed if blank.'
    ],
    'uiType': 'numberInput',
  },

  [SectionNumberType.COMMERCIAL_PHONE]: {
    'label': '156.Commercial: Phone',
    'hint': [
      '- This field completes (does not replace) the field "Agency phone".',
      '- Must contain all the numbers forming the phone number, including international code with correct separators:"/" and "."'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.COMMERCIAL_EMAIL]: {
    'label': '157.Commercial: Email',
    'hint': [
      'This field completes (does not replace) the Agency email.',
      '50 characters max'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.COMMERCIAL_TITLE]: {
    'label': '159.Commercial: Title',
    'hint': [
      '- This field completes (does not replace) the field ""Title of the agency"".',
      '-Displayed as : 01 Mr / 02 Mme 03 Mlle'
    ],
    'values': [
      { 'value': '1', 'label': 'Mr' },
      { 'value': '2', 'label': 'Mme' },
      { 'value': '3', 'label': 'Mlle' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.COMMERCIAL_NAME]: {
    'label': '160.Commercial: Name',
    'hint': '- This field completes (does not replace) the field "Agency name".',
    'uiType': 'textinput'
  },
  [SectionNumberType.PRICE_SOLD_RENTED]: {
    'label': '161.Price sold/rented',
    'hint': [
      'In Euros.',
      'Price the property was sold, except Public sale / Monthly price the property was rented.',
      'Price for public sale is in SEC 231, 232, 233 and 234.'
    ],
    'uiType': 'numberInput',
  },
  [SectionNumberType.PRICE_SOLD_RENTED_DISPLAY]: {
    'label': '162.Display price sold/rented',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.DATE_OF_SALE]: {
    'label': '163.Date of the sale',
    'hint': [
      'Date of the sale or rent.',
      'Format: YYYYMMDD'
    ],
    'uiType': 'textinput',
  },
  [SectionNumberType.VIEWING_ARRANGEMENT_FR]: {
    'label': '165.Visit madality FR',
    'uiType': 'textinput',
  },
  [SectionNumberType.VIEWING_ARRANGEMENT_NL]: {
    'label': '166.Visit madality NL',
    'uiType': 'textinput',
  },
  [SectionNumberType.VIEWING_ARRANGEMENT_EN]: {
    'label': '167.Visit madality EN',
    'uiType': 'textinput',
  },
  [SectionNumberType.VENUE_EN]: {
    'label': '168.Place of next session EN',
    'uiType': 'textinput',
  },
  [SectionNumberType.VENUE_NL]: {
    'label': '169.Place of next session NL',
    'uiType': 'textinput',
  },
  [SectionNumberType.VENUE_FR]: {
    'label': '170.Place of next session FR',
    'uiType': 'textinput',
  },
  [SectionNumberType.MISCELLANEOUS_EQUIPMENT_EN]: {
    'label': '174.Miscellaneous equipments EN',
    'hint': 'Only available for Offices (category 2), Industries (category 3) and  Businesses (category 4)',
    'uiType': 'textinput',
  },
  [SectionNumberType.MISCELLANEOUS_EQUIPMENT_NL]: {
    'label': '175.Miscellaneous equipments NL',
    'hint': 'Only available for Offices (category 2), Industries (category 3) and  Businesses (category 4)',
    'uiType': 'textinput',
  },
  [SectionNumberType.MISCELLANEOUS_EQUIPMENT_FR]: {
    'label': '176.Miscellaneous equipments FR',
    'hint': 'Only available for Offices (category 2), Industries (category 3) and  Businesses (category 4)',
    'uiType': 'textinput',
  },
  [SectionNumberType.INDOOR_MISCELLANEOUS_EQUIPMENT_EN]: {
    'label': '177.Indoor Miscellaneous equipments EN',
    'hint': 'Only available for Offices (category 2), Industries (category 3) and  Businesses (category 4)',
    'uiType': 'textinput',
  },
  [SectionNumberType.INDOOR_MISCELLANEOUS_EQUIPMENT_NL]: {
    'label': '178.Indoor Miscellaneous equipments NL',
    'hint': 'Only available for Offices (category 2), Industries (category 3) and  Businesses (category 4)',
    'uiType': 'textinput',
  },
  [SectionNumberType.INDOOR_MISCELLANEOUS_EQUIPMENT_FR]: {
    'label': '179.Indoor Miscellaneous equipments FR',
    'hint': 'Only available for Offices (category 2), Industries (category 3) and  Businesses (category 4)',
    'uiType': 'textinput',
  },
  [SectionNumberType.URBAN_EN]: {
    'label': '180.Urbanism EN',
    'uiType': 'textinput',
  },
  [SectionNumberType.URBAN_NL]: {
    'label': '181.Urbanism NL',
    'uiType': 'textinput',
  },
  [SectionNumberType.URBAN_FR]: {
    'label': '182.Urbanism FR',
    'uiType': 'textinput',
  },
  [SectionNumberType.MONTHLY_ANNUITY]: {
    'label': '183.Monthly annuity',
    'hint': [
      'Only for annuity sale',
      'No decimal',
      'between 1 and 100000 EUR'
    ],
    'uiType': 'numberInput',
  },
  [SectionNumberType.INDEXED_ANNUITY]: {
    'label': '184.Indexed annuity',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.JOINT_LIFE_ANNUITY]: {
    'label': '185.Joint life annuity',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },

  [SectionNumberType.IS_BARE_OWNERSHIP]: {
    'label': '186.Is Bare ownership',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },

  [SectionNumberType.NUMBER_OF_HEADS]: {
    'label': '187.Number of heads',
    'hint': 'Only for annuity sale from 0 to 2',
    'uiType': 'numberInput',
    'minValue': 0,
    'maxValue': 2,
  },
  [SectionNumberType.AGE_OF_VENDOR_1]: {
    'label': '188.Age of vendor 1',
    'hint': 'Only for annuity sale from 0 to 150',
    'uiType': 'numberInput',
    'minValue': 0,
    'maxValue': 150,
  },
  [SectionNumberType.AGE_OF_VENDOR_2]: {
    'label': '189.Age of vendor 2',
    'hint': 'Only for annuity sale from 0 to 150',
    'uiType': 'numberInput',
    'minValue': 0,
    'maxValue': 150,
  },
  [SectionNumberType.MAXIMUM_DURATION_FR]: {
    'label': '190.Maximum duration FR',
    'uiType': 'textinput',
    'maxLength': 100,
  },
  [SectionNumberType.MAXIMUM_DURATION_NL]: {
    'label': '191.Maximum duration NL',
    'uiType': 'textinput',
    'maxLength': 100,
  },
  [SectionNumberType.MAXIMUM_DURATION_EN]: {
    'label': '192.Maximum duration EN',
    'uiType': 'textinput',
    'maxLength': 100,
  },
  [SectionNumberType.UNIQUE_SESSION]: {
    'label': '193.Unique session',
    'hint': 'Only for Public sales',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.SMALL_PETS]: {
    'label': '194.Small pets',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.BIG_PETS]: {
    'label': '195.Small pets',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.SOLD_OR_RENTED]: {
    'label': '196.Sold/Rented',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
      { 'value': 'X', 'label': 'Option' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.MAKE_OFFER]: {
    'label': '197.Make offer',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.VIDEO_PHONE]: {
    'label': '198.Videophone',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.AVAILABLE_AS_OF]: {
    'label': '199.Available as of',
    'values': [
      { 'value': AvailableAsOf.UPON_EXCHANGE_OF_DEEDS, 'label': 'A l"acte / Bij akte / Upon exchange of deeds' },
      { 'value': AvailableAsOf.TO_BE_DEFINED, 'label': 'A définir / Te bepalen / To define' },
      { 'value': AvailableAsOf.AT_DELIVERY, 'label': 'A la livraison / Bij levering / At delivery' },
      { 'value': AvailableAsOf.DEPENDING_ON_THE_TENANT, 'label': 'En fonction du locataire / Afhankelijk van de huurder / Depending on the tenant' },
      { 'value': AvailableAsOf.IMMEDIATELY, 'label': 'Immédiatement / Onmiddellijk / Immediately' }
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.PRICE_PER_SQM]: {
    'label': '200.Price m2/an',
    'hint': [
      'In euros',
      'Only available for offices rental, business and industry'
    ],
    'uiType': 'numberInput',
  },
  [SectionNumberType.PRICE_GOODWILL]: {
    'label': '201.Price of business goodwill',
    'hint': 'In euros',
    'uiType': 'numberInput',
  },
  [SectionNumberType.VIRTUAL_TOUR_URL]: {
    'label': '202.Virtuaal tour URL',
    'hint': 'Must begin with "http://" or "https://".',
    'uiType': 'textinput',
    'maxLength': 100,
  },
  [SectionNumberType.TRANSACTION_SUB_TYPE]: {
    'label': '204.Transaction subtype',
    'hint': [
      'By default: G',
      'Not editable',
      'Mandatory when SEC 20 = Sale',
      'P only available for type of client NOT',
      'R only available for type of client NOT AGO AGE'
    ],
    'values': [
      { 'value': 'G', 'label': 'Private sale' },
      { 'value': 'P', 'label': 'Public sale' },
      { 'value': 'R', 'label': 'Annuanity sale' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.PROFESSION_SURFACE]: {
    'label': '205.Liberal profession surface',
    'hint': 'Value in square meters',
    'uiType': 'numberInput',
  },
  [SectionNumberType.DRESSING_ROOM]: {
    'label': '206.Dressing room',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.SWINMMING_POOL]: {
    'label': '208.Swimming pool',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.LIVING_ROOM]: {
    'label': '210.Living room',
    'hint': 'Switch indicating if it exists (automatically Y if matching surface is > 0)',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.DINING_ROOM]: {
    'label': '211.Dining room',
    'hint': 'Switch indicating if it exists (automatically Y if matching surface is > 0)',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.TERRACE]: {
    'label': '212.Terrace',
    'hint': 'Switch indicating if it exists (automatically Y if matching surface is > 0)',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.ATTIC]: {
    'label': '213.Attic',
    'hint': 'automatically Y if matching surface is > 0',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
      { 'value': 'I', 'label': 'Isolated' }
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.BASEMENT]: {
    'label': '213.Basement',
    'hint': 'automatically Y if matching surface is > 0',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.OFFICE]: {
    'label': '213.Office',
    'hint': 'automatically Y if matching surface is > 0',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.SHOWROOM_SURFACE]: {
    'label': '216.Showroom surface',
    'hint': 'Value in square meters',
    'uiType': 'numberInput',
  },
  [SectionNumberType.MAX_AVAILABLE_HEIGHT]: {
    'label': '217.Clear height',
    'hint': 'Value in meters, decimals allowed.',
    'uiType': 'numberInput',
  },
  [SectionNumberType.AVAILABLE_SURFACE]: {
    'label': '218.Available surface',
    'hint': [
      'Value in squere meters. Decimals allowed. "0 or blank, and from 5 to 20.000."',
      'For offices, business and industries, including the ads linked to a project.'
    ],
    'uiType': 'numberInput',
    'maxValue': 20000,
  },
  [SectionNumberType.NUMBER_OF_INDIVIDUAL_INSIDE_PARKINGS]: {
    'label': '219.Number of individual inside parkings',
    'hint': 'Number of closed boxes (89), inside garages (90 and 219) are summed',
    'uiType': 'textinput'
  },
  [SectionNumberType.NUMBER_OF_ADDITIONAL_INDIVIDUAL_PARKINGS]: {
    'label': '220.Number of additional individual parkings',
    'hint': 'Number of outside places (91) and number of individual parkings (220) are summed',
    'uiType': 'textinput'
  },
  [SectionNumberType.SHOP_WINDOW_WIDTH]: {
    'label': '221.Width store window',
    'hint': 'Value in meters',
    'uiType': 'numberInput',
  },
  [SectionNumberType.DETAILED_PLAN]: {
    'label': '222.Detailed plan',
    'hint': 'Complete adress of the property is mentionned on the site: "house" icon: precise mapping',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.SITUATION_PLAN]: {
    'label': '223.Situation plan',
    'hint': [
      'Only ZIP is mentionned on the site:',
      'Average mapping of the area',
    ],
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.YEARLY_RENTAL_PRICE]: {
    'label': '224.Yearly rental price',
    'hint': [
      'In Euros',
      '',
      'RENTALS (CATEGORY 2, 3, 4):',
      '- Mandatory if SEC 7 is blank.',
      '- If SEC 224 is blank, it will display the amount of SEC 7 multiplied per 12.',
      'RENTALS (CAT 2, 3, 4)', '_ Monthly rental price in Euros.',
      '_SEC 7 is mandatory only if SEC 224 is blank.',
      '_ If SEC 7 is blank, it will consider the amount from SEC 224 divided by 12.',
      '_ If SEC 7 is completed but SEC 224 is blank, SEC 224 will consider the amount from SEC 7 divided by 12.',
      '_Accepted values: from 300 to 12.000.000.',
      '',
      'Displayed only for offices, businesses and industries.'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.HIGHER_BID_RIGHT]: {
    'label': '228.higher bid right',
    'hint': 'Only for Public sales',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.HIGHER_BID_PRIME]: {
    'label': '229.Higher bid prime',
    'hint': 'In Euros',
    'uiType': 'numberInput',
  },
  [SectionNumberType.HIGHER_BID_DATE_LIMIT]: {
    'label': '230.Limit date for higher bid',
    'hint': 'Date format: YYYYMMDDHHMM (must always be 12 characters!)',
    'uiType': 'textinput'
  },
  [SectionNumberType.RESERVE_PRICE]: {
    'label': '231.Reserve price',
    'hint': 'In Euros',
    'uiType': 'numberInput',
  },
  [SectionNumberType.SESSION_PRICE]: {
    'label': '233.Session price',
    'hint': 'In Euros',
    'uiType': 'numberInput',
  },
  [SectionNumberType.COMPLEMENTARY_SESSION_PRICE]: {
    'label': '234.Complementary session price',
    'hint': 'In Euros',
    'uiType': 'numberInput',
  },
  [SectionNumberType.SESSION_DATE_HOUR]: {
    'label': '235.Date and hour of the session',
    'hint': 'Date format: YYYYMMDDHHMMSS',
    'uiType': 'textinput'
  },
  [SectionNumberType.COMPLEMENTARY_SESSION_DATE_HOUR]: {
    'label': '237.Date and hour of the complementary session',
    'hint': 'Date format: YYYYMMDDHHMMSS',
    'uiType': 'textinput'
  },
  [SectionNumberType.NEXT_SESSION_PLACE]: {
    'label': '238.Place of the next session',
    'hint': [
      'Multilingual field that has to be filled with opening and closing tags.',
      'Max 510 characters.'
    ],
    'uiType': 'textinput',
    'maxLength': 510,
  },
  [SectionNumberType.DESCRIPTION_ADDITIONAL_FR]: {
    'label': '241.Various (more infos) FR',
    'hint': 'These three successive Sections contain, respectively, in French, Dutsh and English, an additional information to the label "flash" of the property (see Sections 11, 12) and may contain a maximum of 750 characters WITH THE STRICT EXCEPTION FOR ANY CHARACTER OF CONTROL (<TAB>, <LF> <RET >, <EOL>, etc ...). This is very important because the application will see these characters as an end of sheet or a transition to a next Section. If your application allows the encoding of items with these control characters, it is imperative the filter when exporting and replace, for example, by a character "Spacing" (ASCII 32). No HTML is allowed in this field.',
    'uiType': 'textinput',
    'maxLength': 750,
  },
  [SectionNumberType.DESCRIPTION_ADDITIONAL_NL]: {
    'label': '242.Various (more infos) NL',
    'hint': 'These three successive Sections contain, respectively, in French, Dutsh and English, an additional information to the label "flash" of the property (see Sections 11, 12) and may contain a maximum of 750 characters WITH THE STRICT EXCEPTION FOR ANY CHARACTER OF CONTROL (<TAB>, <LF> <RET >, <EOL>, etc ...). This is very important because the application will see these characters as an end of sheet or a transition to a next Section. If your application allows the encoding of items with these control characters, it is imperative the filter when exporting and replace, for example, by a character "Spacing" (ASCII 32). No HTML is allowed in this field.',
    'uiType': 'textinput',
    'maxLength': 750,
  },
  [SectionNumberType.DESCRIPTION_ADDITIONAL_EN]: {
    'label': '243.Various (more infos) EN',
    'hint': 'These three successive Sections contain, respectively, in French, Dutsh and English, an additional information to the label "flash" of the property (see Sections 11, 12) and may contain a maximum of 750 characters WITH THE STRICT EXCEPTION FOR ANY CHARACTER OF CONTROL (<TAB>, <LF> <RET >, <EOL>, etc ...). This is very important because the application will see these characters as an end of sheet or a transition to a next Section. If your application allows the encoding of items with these control characters, it is imperative the filter when exporting and replace, for example, by a character "Spacing" (ASCII 32). No HTML is allowed in this field.',
    'uiType': 'textinput',
    'maxLength': 750,
  },
  [SectionNumberType.NAME_OF_RESIDENCE]: {
    'label': '246.Name of Residence',
    'hint': 'Name of residence or building of the property (max 50car)',
    'uiType': 'textinput',
    'maxLength': 50,
  },
  [SectionNumberType.NEIGHBORHOOD]: {
    'label': '247.Neighborhood',
    'hint': 'Specific name of a neighborhood (max 50car)',
    'uiType': 'textinput',
    'maxLength': 50,
  },
  [SectionNumberType.VIEW_ON_SEA]: {
    'label': '248.View on the sea',
    'hint': '"MER" if there is a view on the sea',
    'uiType': 'textinput'
  },
  [SectionNumberType.AIR_CONDITIONER]: {
    'label': '267.Air-conditioning',
    'hint': 'Presence of a fixed air-conditioning system in the property.',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.INTERNET_ACCESS]: {
    'label': '268.Internet access',
    'hint': 'Detail of the multimedia equipment',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.FLOOD_ZONE_TYPE]: {
    'label': '289.Flood-risk area',
    'values': [
      { 'value': FloodRisk.NOT_COMMUNICATED, 'label': 'Not communcated' },
      { 'value': FloodRisk.RECOGNIZED_FLOOD_ZONE, 'label': 'Property partially or completely located in a recognized flood zone' },
      { 'value': FloodRisk.RECOGNIZED_N_CIRCUMSCRIBED_FLOOD_ZONE, 'label': 'Property partially or completely located in a recognized flood zone and located in a circumscribed flood zone' },
      { 'value': FloodRisk.RECOGNIZED_N_CIRCUMSCRIBED_WATERSIDE_FLOOD_ZONE, 'label': 'Property partially or completely located in a recognized flood zone and located in a circumscribed waterside zone' },
      { 'value': FloodRisk.POSSIBLE_FLOOD_ZONE, 'label': 'Property partially or completely located in a possible flood zone' },
      { 'value': FloodRisk.POSSIBLE_N_CIRCUMSCRIBED_FLOOD_ZONE, 'label': 'Property partially or completely located in a possible flood zone and located in a circumscribed flood zone' },
      { 'value': FloodRisk.POSSIBLE_N_CIRCUMSCRIBED_WATERSIDE_ZONE, 'label': 'Property partially or completely located in a possible flood zone and located in a circumscribed waterside zone' },
      { 'value': FloodRisk.CIRCUMSCRIBED_FLOOD_ZONE, 'label': 'Property partially or completely located in a circumscribed flood zone' },
      { 'value': FloodRisk.CIRCUMSCRIBED_WATERSIDE_ZONE, 'label': 'Property partially or completely located in a circumscribed waterside zone' },
      { 'value': FloodRisk.NON_FLOOD_ZONE, 'label': 'Property located in a non-flood zone' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.TERRACE_ORIENTATION]: {
    'label': '290.Terrace orientation',
    'hint': 'For cat 6 (Fields), orientation when looking at the façade from the street.',
    'values': [
      { 'value': Orientation.NORTH, 'label': 'Nord / Noord / North' },
      { 'value': Orientation.SOUTH, 'label': 'Sud / Zuid / South' },
      { 'value': Orientation.EAST, 'label': 'Est / Oost / East' },
      { 'value': Orientation.NORTH_EAST, 'label': 'Nord-Est / Noordoost / Northeast' },
      { 'value': Orientation.SOUTH_EAST, 'label': 'Sud-Est / Zuidoost / Southeast' },
      { 'value': Orientation.WEST, 'label': 'Ouest / West / West' },
      { 'value': Orientation.NORTH_WEST, 'label': 'Nord-Ouest / Noord-West / North-West' },
      { 'value': Orientation.SOUTH_WEST, 'label': 'Sud-Ouest / Zuid-West / South-West' },
    ],
    'uiType': 'dropdown',
  },
  [SectionNumberType.NUMBER_OF_ACCESS_DOORS]: {
    'label': '293.Number of access doors',
    'hint': 'For industries',
    'uiType': 'numberInput',
  },
  [SectionNumberType.NUMBER_OF_SECTIONAL_DOORS]: {
    'label': '294.Number of sectional doors',
    'hint': 'For industries',
    'uiType': 'numberInput',
  },
  [SectionNumberType.NUMBER_OF_SLIDING_DOORS]: {
    'label': '295.Number of sliding doors',
    'hint': 'For industries',
    'uiType': 'numberInput',
  },
  [SectionNumberType.NUMBER_OF_LOADING_DOCKS]: {
    'label': '296.Number of loading docks',
    'hint': 'For industries',
    'uiType': 'numberInput',
  },
  [SectionNumberType.NUMBER_OF_COVERED_DOCKS]: {
    'label': '297.Number of covered docks',
    'hint': 'For industries',
    'uiType': 'numberInput',
  },
  [SectionNumberType.NUMBER_OF_DOCKS_WITH_LEVERS]: {
    'label': '298.Number of docks with levers',
    'hint': 'For industries',
    'uiType': 'numberInput',
  },
  [SectionNumberType.ARCHITECT]: {
    'label': '299.Architect',
    'hint': 'For houses to build, switch indicating if it is included in the price.',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.STABILITY_EGINEER]: {
    'label': '300.Stability engineer',
    'hint': 'For houses to build, switch indicating if it is included in the price.',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.SAFETY_COORDINATOR]: {
    'label': '301.Safety coordinaor',
    'hint': 'For houses to build, switch indicating if it is included in the price.',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.GROUND_STUDY]: {
    'label': '302.Ground study',
    'hint': 'For houses to build, switch indicating if it is included in the price.',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.KITCHEN_INCLUDED]: {
    'label': '303.Kitchen include',
    'hint': 'For houses to build, switch indicating if it is included in the price.',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.OUTDOOR_ARANGEMENT]: {
    'label': '304.Outdoor arrangement',
    'hint': 'For houses to build, switch indicating if it is included in the price.',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.BLOWER_DOOR_TEST]: {
    'label': '305."Blower door" test',
    'hint': 'For houses to build, switch indicating if it is included in the price.',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.STRUCTURAL_WORK_PRICE]: {
    'label': '306.Price structural works',
    'hint': [
      'For houses to build.',
      'From 2.500 to 35.000.000.'
    ],
    'uiType': 'numberInput',
    'minValue': 2500,
    'maxValue': 35000000,
  },
  [SectionNumberType.CONSTRUCTION_PERIOD]: {
    'label': '307.Construction period',
    'hint': [
      'For houses to build.',
      'In months'
    ],
    'uiType': 'numberInput',
  },
  [SectionNumberType.HOUSES_TO_BUILD_TYPE]: {
    'label': '308.Type of building',
    'hint': 'For houses to build',
    'values': [
      { 'value': '1', 'label': 'Maçonnerie traditionnelle / Traditioneel metselwerk / Traditional construction' },
      { 'value': '2', 'label': 'Maison préfabriquée / Prefab / Prefabricated' },
      { 'value': '3', 'label': 'Ossature métallique / Staalbouw / Metal frame' },
      { 'value': '4', 'label': 'Ossature bois / Houtskeletbouw / Wood frame' },
      { 'value': '5', 'label': 'Bois massif / Massief hout / Massive wood' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.HOUSES_TO_BUILD_ARCHITECTURE]: {
    'label': '309.Architectural style',
    'hint': 'For houses to build',
    'values': [
      { 'value': '1', 'label': 'Classique / Klassiek / Classic' },
      { 'value': '2', 'label': 'Rustique / Rustiek / Rustic' },
      { 'value': '3', 'label': 'Contemporain / Modern / Contemporary' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.HOUSES_TO_BUILD_ENERGY_PERFORMANCE]: {
    'label': '309.Architectural style',
    'hint': 'For houses to build',
    'values': [
      { 'value': '1', 'label': 'Classique / Klassiek / Classic' },
      { 'value': '2', 'label': 'Rustique / Rustiek / Rustic' },
      { 'value': '3', 'label': 'Contemporain / Modern / Contemporary' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.CLEARANCE_HEIGHT]: {
    'label': '310.Clearance height',
    'hint': [
      'In meters',
      'For industries'
    ],
    'uiType': 'numberInput',
  },
  [SectionNumberType.ON_THE_SPOTLIGHT_FORMAT]: {
    'label': '313.Format "On the spotlight"',
    'hint': [
      '. Determine if the Ad benefit of a visibility option "On the spotlight"',
      '. Deactivates "L" and "XL" format',
    ],
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.THEORETICAL_ENERGY_CONSUMTION_YEARLY]: {
    'label': '314.PEB - Total theorical consumption of primary energy (kWh/an)',
    'hint': [
      '. Legaly mandatory for Wallonia',
      '. Value in kWh/an in the "energy" tab',
      '. Any other value will be displayed as "not communicated"',
    ],
    'uiType': 'numberInput',
    'minValue': 1,
    'maxValue': 999999,
  },
  [SectionNumberType.ENERGY_CLASS_LABEL]: {
    'label': '315.PEB - Label energetic class',
    'hint': [
      '. Legaly mandatory for Wallonia',
      '. Only valid in Wallonia',
      '. Will display the logo in "Energetic class" in the "energy" tab',
      '. Any other value will be displayed as "not communicated"'
    ],
    'values': [
      { 'value': EPC_SCORE['NONE'], 'label': 'Not Disclosed' },
      { 'value': EPC_SCORE['A++'], 'label': 'A++' },
      { 'value': EPC_SCORE['A+'], 'label': 'A+' },
      { 'value': EPC_SCORE['A'], 'label': 'A' },
      { 'value': EPC_SCORE['B'], 'label': 'B' },
      { 'value': EPC_SCORE['C'], 'label': 'C' },
      { 'value': EPC_SCORE['D'], 'label': 'D' },
      { 'value': EPC_SCORE['E'], 'label': 'E' },
      { 'value': EPC_SCORE['F'], 'label': 'F' },
      { 'value': EPC_SCORE['G'], 'label': 'G' },
      { 'value': EPC_SCORE['A+_A++'], 'label': 'A+ / A++' },
      { 'value': EPC_SCORE['A_A++'], 'label': 'A / A++' },
      { 'value': EPC_SCORE['A_A+'], 'label': 'A / A+' },
      { 'value': EPC_SCORE['B_A++'], 'label': 'B / A++' },
      { 'value': EPC_SCORE['B_A+'], 'label': 'B / A+' },
      { 'value': EPC_SCORE['B_A'], 'label': 'B / A' },
      { 'value': EPC_SCORE['C_A++'], 'label': 'C / A++' },
      { 'value': EPC_SCORE['C_A+'], 'label': 'C / A+' },
      { 'value': EPC_SCORE['C_A'], 'label': 'C / A' },
      { 'value': EPC_SCORE['C_B'], 'label': 'C / B' },
      { 'value': EPC_SCORE['D_A++'], 'label': 'D / A++' },
      { 'value': EPC_SCORE['D_A+'], 'label': 'D / A+' },
      { 'value': EPC_SCORE['D_A'], 'label': 'D / A' },
      { 'value': EPC_SCORE['D_B'], 'label': 'D / B' },
      { 'value': EPC_SCORE['D_C'], 'label': 'D / C' },
      { 'value': EPC_SCORE['E_A++'], 'label': 'E / A++' },
      { 'value': EPC_SCORE['E_A+'], 'label': 'E / A+' },
      { 'value': EPC_SCORE['E_A'], 'label': 'E / A' },
      { 'value': EPC_SCORE['E_B'], 'label': 'E / B' },
      { 'value': EPC_SCORE['E_C'], 'label': 'E / C' },
      { 'value': EPC_SCORE['E_D'], 'label': 'E / D' },
      { 'value': EPC_SCORE['F_A++'], 'label': 'F / A++' },
      { 'value': EPC_SCORE['F_A+'], 'label': 'F / A+' },
      { 'value': EPC_SCORE['F_A'], 'label': 'F / A' },
      { 'value': EPC_SCORE['F_B'], 'label': 'F / B' },
      { 'value': EPC_SCORE['F_C'], 'label': 'F / C' },
      { 'value': EPC_SCORE['F_D'], 'label': 'F / D' },
      { 'value': EPC_SCORE['F_E'], 'label': 'F / E' },
      { 'value': EPC_SCORE['G_A++'], 'label': 'G / A++' },
      { 'value': EPC_SCORE['G_A+'], 'label': 'G / A+' },
      { 'value': EPC_SCORE['G_A'], 'label': 'G / A' },
      { 'value': EPC_SCORE['G_B'], 'label': 'G / B' },
      { 'value': EPC_SCORE['G_C'], 'label': 'G / C' },
      { 'value': EPC_SCORE['G_D'], 'label': 'G / D' },
      { 'value': EPC_SCORE['G_E'], 'label': 'G / E' },
      { 'value': EPC_SCORE['G_F'], 'label': 'G / F' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.DISPLAY_ON_BD]: {
    'label': '318.Publish on Belles Demeures',
    'hint': [
      'No other value than blank, "0" or "1" will be accepted.',
      'By sending "1", the classified will also be exported to "BellesDemeures"',
      'If 318 = 1, fields 319 & 320 have to be exported.',
      'Remark : Classifieds needs also to match with "BellesDemeures" luxury criteria"s.'
    ],
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.DISPLAY_ON_IMMOWEB]: {
    'label': '319.Display on Immoweb',
    'hint': [
      'Only treated if field 318 = 1',
      'No other value than blank, "0" or "1" will be accepted.',
      'By sending "0", the classified will ONLY be sent to "BellesDemeures" (won"t be displayed on Immoweb)',
      'If blank, will be automatically be considered as to be published on Immoweb'
    ],
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.DISPLAY_PRICE_BD]: {
    'label': '320.Display price on Belles Demeures',
    'hint': [
      'Only treated is field 318 = 1',
      'No other value than blank, "0" or "1" will be accepted',
      'By sending "0", the price will be hidden on "BellesDemeures"',
      'If blank, will be automatically be considered as price to be published'
    ],
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.DISPLAY_MODE]: {
    'label': '321.Standard Display Mode',
    'hint': [
      '"0" = Detailed display mode (Display mode that presents all Individual Units).',
      '"1" = Standard display mode: Display only Mnemomniques  (Display mode that GROUPS Individual Units by Mnémonique & Phase).',
      'REMARK : In all cases, even if Grouped Display is selected (value "1"), all units of the projects still have to be sent'
    ],
    'values': [
      { 'value': '0', 'label': 'Detailed' },
      { 'value': '1', 'label': 'Standard' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.DISPLAY_MODE_SOLD_PERCENTAGE]: {
    'label': '322.Display Mode Sold percentage',
    'hint': [
      'Determine the type of display of the sold percentage for projects.',
      'Can only be applied at level of the Project.',
      'Only displayed if the project is "tilt" (all units are published).',
      '3 possible values:',
      '-"calculated" =  the value is automatically calculated based on information received in the field 196 (sold/rented)',
      '-"manual" = display the value contained in the field 323',
      '-"hidden" = No sold percentage is displayed',
      'By default and if blank, will apply the "calculated" display.'
    ],
    'values': [
      { 'value': 'USER_DEFINED', 'label': 'Manual' },
      { 'value': 'CALCULATED', 'label': 'Calculated' },
      { 'value': 'HIDDEN', 'label': 'Hidden' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.MANUAL_SOLD_PERCENTAGE]: {
    'label': '323.Manual Sold percentage',
    'hint': [
      'Can only be applied at level of the Project',
      'Only displayed if the project is "tilt" (all units are published).',
      'Will be displayed if field 322 (Display Sold Percentage) contains "manual".',
      'Example: the display will be 50% if value received = 50.',
      'to Force the % to display : possible values 0 -> 100.'
    ],
    'uiType': 'numberInput',
    'minValue': 0,
    'maxValue': 100,
  },
  [SectionNumberType.REMARKETING_URL_FR]: {
    'label': '325.Remarketing URL FR',
    'hint': [
      'URL used to redirect the surfer to the classified FR on the client Website in case he chose to subscribe to the remarketing product on Immoweb.',
      'Max 100 car.',
      'Must begin with "http://" or "https://".',
      'Must have the same root as the client Website.'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.REMARKETING_URL_NL]: {
    'label': '326.Remarketing URL NL',
    'hint': [
      'URL used to redirect the surfer to the classified FR on the client Website in case he chose to subscribe to the remarketing product on Immoweb.',
      'Max 100 car.',
      'Must begin with "http://" or "https://".',
      'Must have the same root as the client Website.'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.REMARKETING_URL_EN]: {
    'label': '327.Remarketing URL EN',
    'hint': [
      'URL used to redirect the surfer to the classified FR on the client Website in case he chose to subscribe to the remarketing product on Immoweb.',
      'Max 100 car.',
      'Must begin with "http://" or "https://".',
      'Must have the same root as the client Website.'
    ],
    'uiType': 'textinput'
  },
  [SectionNumberType.P_SCORE]: {
    'label': '328.P Score',
    'hint': 'Flood Risk - P Score. One of A, B, C or D.',
    'values': [
      { 'value': 'A', 'label': 'A' },
      { 'value': 'B', 'label': 'B' },
      { 'value': 'C', 'label': 'C' },
      { 'value': 'D', 'label': 'D' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.G_SCORE]: {
    'label': '329.G Score',
    'hint': 'Flood Risk - G Score. One of A, B, C or D.',
    'values': [
      { 'value': 'A', 'label': 'A' },
      { 'value': 'B', 'label': 'B' },
      { 'value': 'C', 'label': 'C' },
      { 'value': 'D', 'label': 'D' },
    ],
    'uiType': 'dropdown'
  },
  [SectionNumberType.NEW_BUILDING_LAND_PRICE]: {
    'label': '330.Land price excluding taxes',
    'hint': [
      'Land price without taxes or registration fees.',
      'Note: if there is no land price, please indicate 0€.',
      'minimum: 1 ; maximum: 7 000 000'
    ],
    'uiType': 'numberInput',
    'minValue': 1,
    'maxValue': 7000000,
  },
  [SectionNumberType.NEW_BUILDING_VAT]: {
    'label': '331.Building VAT',
    'hint': [
      'By law, new buildings’ prices are subject to VAT :',
      'Usually 21%.',
      'If social housing with CPAS vendor: 12%.',
      'If Social housing with seller regional housing company or social housing company approved by them: 6%.',
      'Note: if there is no building and/or no VAT, please indicate 0€.',
      'minimum: 1 ; maximum: 7 000 000'
    ],
    'uiType': 'numberInput',
    'minValue': 1,
    'maxValue': 7000000,
  },
  [SectionNumberType.NEW_BUILDING_TAXES]: {
    'label': '332.Taxes related to land',
    'hint': [
      'In principle: Either VAT or registrations fees apply depending on conditions below.',
      'VAT of 21% is payable on the corresponding plot of land if all 3 of the following conditions are met: It is permitted to build on the land and the building that is erected is sold inclusive of VAT',
      'The land and building are sold by one and the same person',
      'The land is sold together with the building.',
      'If these conditions are not met, registration fees are applied.',
      'Note: if there is no land and/or no land taxes, please indicate 0€.',
      'minimum: 1 ; maximum: 7 000 000'
    ],
    'uiType': 'numberInput',
    'minValue': 1,
    'maxValue': 7000000,
  },
  [SectionNumberType.NEW_BUILDING_TOTAL_PRICE]: {
    'label': '333.Total price including taxes',
    'hint': [
      'The total price of the new build is the sum of the 4 fields:',
      '',
      '= Building price excluding VAT + Land price excluding taxes + Building VAT + Taxes related to land.',
      '',
      'minimum: 2500 ; maximum: 35000000'
    ],
    'uiType': 'numberInput',
    'minValue': 2500,
    'maxValue': 35000000,
  },
  [SectionNumberType.ASBESTOS_CERTIFICATE]: {
    'label': '334.Has asbestos certificate',
    'hint': 'Switch for indicating that an asbestos certificate is available for this property.',
    'values': [
      { 'value': 'Y', 'label': 'Yes' },
      { 'value': 'N', 'label': 'No' },
    ],
    'uiType': 'dropdown'
  },
};

export { sectionsConfig };
