import { SectionNumberType } from '../types/classifiedEnums';
import { ClassifiedDescription, SectionDescription } from '../types/types';
import { Mandatory, MandatoryForValues, RegexpCheck, RegexpCheckForValues, RequiresSectionValue, RequiresSectionValueForValues } from '../types/validators';
import { validatorsConfig } from './validatorsConfig';

const regexpCheck = (id: string, section: SectionDescription, validator: RegexpCheck) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const regexp = new RegExp(validator.pattern);
  const result = regexp.test(section.value);
  if (!result) {
    const text = `Section ${id} doesn't meet required format`;
    validator.type === 'error' ? errors.push(text) : warnings.push(text);
  }

  return { errors, warnings };
};

const regexpCheckForValues = (id: string, section: SectionDescription, classified: ClassifiedDescription, validator: RegexpCheckForValues) => {
  const errors: string[] = [];
  const warnings: string[] = [];

  for (const otherSectionId in validator) {
    const otherSection = classified[otherSectionId];
    const validatorOtherSection = validator[otherSectionId as SectionNumberType];
    if (otherSection && validatorOtherSection && Object.hasOwn(validatorOtherSection, otherSection.value)) {
      const subValidator = validatorOtherSection[otherSection.value];
      const result = regexpCheck(id, section, subValidator);
      errors.push(...result.errors);
      warnings.push(...result.warnings);
    }
  }

  return { errors, warnings };
};

const mandatory = (id: string, classified: ClassifiedDescription, validator: Mandatory) => {
  const errors: string[] = [];
  for (const section of validator) {
    if (!Object.hasOwn(classified, section)) {
      errors.push(`Section ${section} is mandatory for section ${id}`);
    }
  }
  return errors;
};

const mandatoryForValues = (id: string, section: SectionDescription, classified: ClassifiedDescription, validator: MandatoryForValues) => {
  const errors: string[] = [];
  if (Object.hasOwn(validator, section.value)) {
    const result = mandatory(id, classified, validator[section.value] as Mandatory);
    errors.push(...result);
  }
  return errors;
};
// TODO update validator
const requiresSectionValue = (id: string, classified: ClassifiedDescription, validator: RequiresSectionValue) => {
  const errors: string[] = [];
  const warnings: string[] = [];

  for (const otherSectionId in validator) {
    const otherValidator = validator[otherSectionId as SectionNumberType];
    const otherSection = classified[otherSectionId];
    if(!otherSection) {
      errors.push(`Section ${id} is not suitable for value of section ${otherSectionId}`);
    } else if (otherValidator && !otherValidator.acceptedValues.includes(otherSection.value)) {
      const text = `Section ${id} is restricted for current value of section ${otherSectionId}`;
      otherValidator.type === 'error' ? errors.push(text) : warnings.push(text);
    }
  }

  return { errors, warnings };
};
// TODO update validator
const requiresSectionValueForValues = (id: string, section: SectionDescription, classified: ClassifiedDescription, validator: RequiresSectionValueForValues) => {
  const errors: string[] = [];

  for (const otherSectionId in validator) {
    const otherSection = classified[otherSectionId] as SectionDescription;
    const otherValidator = validator[otherSectionId as SectionNumberType];
    if(!otherSection) {
      errors.push(`Section ${id} is not suitable for value of section ${otherSectionId}`);
    } else if (otherValidator && !otherValidator[otherSection.value].includes(section.value)) {
      errors.push(`Section ${id} is not suitable for value of section ${otherSectionId}`);
    }
  }

  return errors;
};


const minValue = (id: string, section: SectionDescription, minValue: number) => {
  const value = Number(section.value);
  if (value && value < minValue) {
    return `Section ${id} doesn't meet minimum value requirement: ${minValue}`;
  }
};

const maxValue = (id: string, section: SectionDescription, maxValue: number) => {
  const value = Number(section.value);
  if (value && value > maxValue) {
    return `Section ${id} doesn't meet maximum value requirement: ${maxValue}`;
  }
};

const minLength = (id: string, section: SectionDescription, minLength: number) => {
  if (section.value.length < minLength) {
    return `Section ${id} doesn't meet minimum length requirement: ${minLength}`;
  }
};

const maxLength = (id: string, section: SectionDescription, maxLength: number) => {
  if (section.value.length > maxLength) {
    return `Section ${id} doesn't meet maximum length requirement: ${maxLength}`;
  }
};

const runSectionValidators = (id: string, section: SectionDescription, classified: ClassifiedDescription): { errors: string[], warnings: string[] } => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const validators = validatorsConfig[id as SectionNumberType];
  if (validators) {
    const keys = Object.keys(validators);
    keys.forEach(validator => {
      switch (validator) {
        case 'regexpCheck': {
          const result = regexpCheck(id, section, validators[validator] as RegexpCheck);
          errors.push(...result.errors);
          warnings.push(...result.warnings);
          break;
        }
        case 'regexpCheckForValues': {
          const result = regexpCheckForValues(id, section, classified, validators[validator] as RegexpCheckForValues);
          errors.push(...result.errors);
          warnings.push(...result.warnings);
          break;
        }
        case 'mandatory': {
          const result = mandatory(id, classified, validators[validator] as Mandatory);
          errors.push(...result);
          break;
        }
        case 'mandatoryForValues': {
          const result = mandatoryForValues(id, section, classified, validators[validator] as MandatoryForValues);
          errors.push(...result);
          break;
        }
        case 'requiresSectionValue': {
          const result = requiresSectionValue(id, classified, validators[validator] as RequiresSectionValue);
          errors.push(...result.errors);
          warnings.push(...result.warnings);
          break;
        }
        case 'requiresSectionValueForValues': {
          const result = requiresSectionValueForValues(id, section, classified, validators[validator] as RequiresSectionValueForValues);
          errors.push(...result);
          break;
        }
        case 'minValue': {
          const error = minValue(id, section, validators[validator] as number);
          if (error) {
            errors.push(error);
          }
          break;
        }
        case 'maxValue': {
          const error = maxValue(id, section, validators[validator] as number);
          if (error) {
            errors.push(error);
          }
          break;
        }
        case 'minLength': {
          const error = minLength(id, section, validators[validator] as number);
          if (error) {
            errors.push(error);
          }
          break;
        }
        case 'maxLength': {
          const error = maxLength(id, section, validators[validator] as number);
          if (error) {
            errors.push(error);
          }
          break;
        }
        default:
          console.warn(`${validator} is not recognized`);
          break;
      }
    });
  }

  return { errors, warnings };
};

const runClassifiedValidators = (classified: ClassifiedDescription): { [key: string]: { errors: string[], warnings: string[] } } => {
  const result: { [key: string]: { errors: string[], warnings: string[] } } = {};
  for (const sectionId in classified) {
    const section = classified[sectionId];
    const { errors, warnings } = runSectionValidators(sectionId, section, classified);
    result[sectionId] = {
      errors, warnings
    };
  }
  return result;
};

export { runSectionValidators, runClassifiedValidators };
