import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { closeFile } from '../actions';

type SectionsInitialState = string[];

type MoveSectionPayload = {
  sectionId: string,
  position: number,
};

const sectionsSlice = createSlice({
  name: 'sectionsData',
  initialState: [] as SectionsInitialState,
  reducers: {
    addSection: (state, action: PayloadAction<string>) => {
      if (state === null) {
        return state;
      }
      const next = [...state];
      next.push(action.payload);
      return next;
    },
    removeSection: (state, action: PayloadAction<string>) => {
      if (state === null) {
        return state;
      }
      const next = [...state];
      const position = next.indexOf(action.payload);
      if (position !== -1) {
        next.splice(position, 1);
      }
      return next;
    },
    moveSection: (state, action: PayloadAction<MoveSectionPayload>) => {
      if (state === null) {
        return state;
      }
      const { sectionId, position } = action.payload;
      const next = { ...state };
      const index = next.indexOf(sectionId);
      if (index) {
        next.splice(index, 1).splice(position, 0, sectionId);
      }
      return next;
    },
    setSections: (_, action: PayloadAction<string[]>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeFile, () => []);
  },
});

export const { addSection, removeSection, moveSection, setSections } = sectionsSlice.actions;

export default sectionsSlice.reducer;
