const routes = {
  index: '/',
  sectionsView: '/views/sections',
  tableView: '/views/table',
  textView: '/views/text',
  jsonView: '/views/json',
  qrcodeView: '/views/qrcode',
};

export default routes;
