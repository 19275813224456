import { Alert, Space } from 'antd';
import Marquee from 'react-fast-marquee';

interface UnderDevelopmentComponentProps {
  title?: string;
}

const UnderDevelopmentComponent: React.FC<UnderDevelopmentComponentProps> = ({
  title,
}) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Alert
        banner
        showIcon
        message={
          <>Warning! <b>{title ?? 'This page'}</b> is not implemented yet.</>
        }
        description={
          <Marquee pauseOnHover gradient={false}>
            This component is in progress.
            This component is in progress.
            This component is in progress.
            This component is in progress.
            This component is in progress.
            This component is in progress.
            This component is in progress.
          </Marquee>
        }
        type="warning"
      />
    </Space>
  );
};

export default UnderDevelopmentComponent;
