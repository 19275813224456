import { ParseResult, parse } from 'papaparse';
import { ClassifiedsDescription, HeaderDescription } from '../../types/types';
import { parseHeader } from './headerParser';
import { classifiedsParser } from './classifiedsParser';
import { store } from '../../store/store';
import { newFile } from '../../store/actions';

enum CSVParts {
  Header,
  Section,
  Classifieds,
  End,
}

const parseBody = (data: string[][]) => {
  const headers: string[] = [];
  const sections: string[] = [];
  const classifieds: string[][] = [];
  let current: CSVParts = CSVParts.Header;
  data.forEach((row) => {
    const value = row[0].toLowerCase();
    if (value === 'start') {
      current = CSVParts.Section;
    } else if (value.substring(0, 3) === 'eof') {
      current = CSVParts.End;
      // store count if available
    } else {
      switch (current) {
        case CSVParts.Header:
          headers.push(row[0]);
          break;
        case CSVParts.Section:
          sections.push(...row);
          current = CSVParts.Classifieds;
          break;
        case CSVParts.Classifieds:
          classifieds.push(row);
          break;
      }
    }
  });

  return {
    headers,
    sections,
    classifieds,
  };
};

const parseTxtFile = async (file: File) => {
  parse(file, {
    delimiter: '\t',
    header: false,
    skipEmptyLines: true,
    complete: function (results: ParseResult<string[]>) {
      const body = parseBody(results.data);

      const headers: HeaderDescription = parseHeader(body.headers, file.name);
      const sections = body.sections;
      const classifiedsOrder = body.classifieds.map(classified => classified[0]); //check if repeat exists?
      const classifieds: ClassifiedsDescription = classifiedsParser(body.classifieds, sections);

      store.dispatch(newFile({ headers, sections, classifieds, classifiedsOrder }));
    },
  });
};

export { parseTxtFile };
