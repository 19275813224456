import { HeaderDescription } from '../../types/types';

const parseHeader = (headers: string[], fileName: string): HeaderDescription => {
  // TODO add checks for corrrect header or throw error
  return {
    softwareName: headers[0],
    pcOrMac: headers[1],
    softwareId: headers[2],
    apiVersion: headers[3],
    action: headers[4],
    fileName: fileName,
  };
};

export { parseHeader };
