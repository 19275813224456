import { Flex, FlexProps, Layout } from 'antd';
import FileHeader from '../../components/classified/FileHeader';
import SectionView from './SectionsView';
import ClassifiedsSider from './SectionsSider';
import styled from 'styled-components';

const ClassifiedView = styled(Flex)<FlexProps>`
  height: 100%;
`;

const Classifieds = () => {
  return (
    <ClassifiedView vertical gap={20}>
      <FileHeader />
      <Layout>
        <ClassifiedsSider />
        <SectionView />
      </Layout>
    </ClassifiedView>
  );
};

export default Classifieds;
