import { Layout, ConfigProvider, theme, GlobalToken } from 'antd';
import styled from 'styled-components';

import appConfig from '../../config';
import Header from '../../components/layout/Header';
import { Outlet } from 'react-router-dom';
import initTheme from '../../theme';

const Content = styled(Layout.Content)`
  padding: 0 48px;
`;

const Canvas = styled.div<{ token: GlobalToken }>`
  margin: 16px 0;
  height: calc(100vh - 162px);
  padding: 24px;
  background: ${props => props.token.colorBgContainer};
  border-radius: ${props => props.token.borderRadiusLG}px;
`;

const Footer = styled(Layout.Footer)`
  text-align: center;
  max-height: 66px;
`;

const Root: React.FC = () => {
  const { token } = theme.useToken();

  return (
    <ConfigProvider
      theme={initTheme(token)}
    >
      <Layout>
        <Header/>
        <Content>
          <Canvas token={token}>
            <Outlet/>
          </Canvas>
        </Content>
        <Footer>
          {appConfig.content.footerText}
        </Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default Root;
