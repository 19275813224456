import { SectionDescription } from '../../types/types';
import { runClassifiedValidators, runSectionValidators } from '../../validators/validators';
import { removeSectionFromClassified, switchClassifiedTab, updateSectionInClassified } from '../actions';
import { removeClassifiedSection, updateClassifiedSection } from '../slices/classifiedsSlice';
import { removeSection } from '../slices/sectionsSlice';
import { setCurrentTab, updateCurrentSections, updateErrorCount } from '../slices/tabsSlice';
import { startAppListening } from './listenerMiddleware';

export const startSectionAddListening = () => {
  // TODO ADD
};

export const startSectionUpdateListening = () => {
  startAppListening({
    actionCreator: updateSectionInClassified,
    effect: (action, listenerApi) => {
      const { classifiedId, sectionId, value } = action.payload;
      const store = listenerApi.getState();
      const classified = store.classifiedsStore[classifiedId];
      const nextSection: SectionDescription = {
        value,
        errors: [],
        warnings: []
      };
      nextSection.value = value;
      const result = runSectionValidators(sectionId, nextSection, classified);
      nextSection.errors = result.errors;
      nextSection.warnings = result.warnings;
      // update error count
      const section = classified[sectionId];
      const classifiedErrorCount = store.tabsStore.classifiedsWithErrors[classifiedId];
      const oldErrorCount = section.errors.length + section.warnings.length;
      const newErrorCount = nextSection.errors.length + section.warnings.length;
      listenerApi.dispatch(updateErrorCount({
        classifiedId,
        errorCount: classifiedErrorCount + (newErrorCount - oldErrorCount),
      }));
      // store next section value
      listenerApi.dispatch(updateClassifiedSection({
        classifiedId,
        sectionId,
        section: nextSection
      }));
    }
  });
};

// TODO fix category removal problem
export const startSectionDeleteListening = () => {
  startAppListening({
    actionCreator: removeSectionFromClassified,
    effect: (action, listenerApi) => {
      const { classifiedId, sectionId } = action.payload;
      listenerApi.dispatch(removeClassifiedSection({ classifiedId, sectionId }));
      const store = listenerApi.getState();
      let classifiedErrorCount = 0;
      const classified = store.classifiedsStore[classifiedId];
      const result = runClassifiedValidators(classified);
      for (const secId in classified) {
        if (result[secId]) {
          const section = classified[secId];
          const { errors, warnings } = result[secId];
          if (JSON.stringify(section.errors) !== JSON.stringify(errors)) {
            listenerApi.dispatch(updateClassifiedSection({
              classifiedId,
              sectionId: secId,
              section: {
                ...section,
                errors,
                warnings
              }
            }));
          }
          classifiedErrorCount += errors.length + warnings.length;
        }
      }
      //update error count
      listenerApi.dispatch(updateErrorCount({
        classifiedId,
        errorCount: classifiedErrorCount,
      }));
      listenerApi.dispatch(updateCurrentSections(Object.keys(classified)));
      // check if section exists in others
      const classifieds = store.classifiedsStore;
      const ids = Object.keys(classifieds);
      const sectionCount = ids.reduce((sum, id) => {
        return Object.hasOwn(classifieds[id], sectionId) ? sum + 1 : sum;
      }, 0);
      if (sectionCount === 0) {
        listenerApi.dispatch(removeSection(sectionId));
      }
    },
  });
};

export const startClassifiedTabSwitchListener = () => {
  startAppListening({
    actionCreator: switchClassifiedTab,
    effect: (action, listenerApi) => {
      const index = action.payload.index;
      const store = listenerApi.getState();
      const id = store.tabsStore.classifiedsOrder[index];
      const sections = Object.keys(store.classifiedsStore[id]);
      listenerApi.dispatch(setCurrentTab({index, id, sections}));
    },
  });
};
