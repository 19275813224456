import type { GlobalToken, ThemeConfig } from 'antd';

const initTheme = (t: GlobalToken): ThemeConfig => ({
  components: {
    Layout: {
      siderBg: '#ffffff'
    }
  },
  token: {
    // Seed Token
    // colorPrimary: '#00b96b',
    colorPrimary: t.geekblue,
    // colorBorder: t.red
    // borderRadius: 2,

    // Alias Token
    // colorBgContainer: t.red,
    // colorBgBase: t.orange
  },
});

export default initTheme;
