import { useCallback } from 'react';
import { Button, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FileState, setFileState } from '../../store/slices/fileStateSlice';
import { RootState } from '../../store/store';
import { parseTxtFile } from '../../csv';
import routes from '../../routes';
import { RcFile } from 'antd/es/upload';

const OpenFileButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fileState } = useSelector((state: RootState) => state.fileStateStore);

  const onFileSelect = useCallback(
    (file: RcFile) => {
      console.log(file);
      if (file.name.endsWith('.txt')) {
        dispatch(
          setFileState({
            fileState: FileState.PARSING,
          })
        );
        parseTxtFile(file);
        navigate(routes.sectionsView);
      } else {
        dispatch(
          setFileState({
            fileState: FileState.ERROR,
            error: 'Unable to read selected file',
          })
        );
      }
      return false;
    },
    [setFileState, parseTxtFile, dispatch]
  );

  return (
    <Upload
      accept='.txt'
      showUploadList={false}
      beforeUpload={onFileSelect}
    >
      <Button
        disabled={fileState === FileState.PARSING}
      >
        Open
      </Button>
    </Upload>
  );
};

export default OpenFileButton;
