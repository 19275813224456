import { clearAllListeners } from '@reduxjs/toolkit';
import { runClassifiedValidators } from '../../validators/validators';
import { closeFile, exportFile, newFile } from '../actions';
import { setClassifieds } from '../slices/classifiedsSlice';
import { FileState, setFileState } from '../slices/fileStateSlice';
import { setHeaderData } from '../slices/headerSlice';
import { setSections } from '../slices/sectionsSlice';
import { setTabsInfo } from '../slices/tabsSlice';
import { startAppListening } from './listenerMiddleware';
import { exportToCSV } from '../../csv';
import { ClassifiedsDescription, HeaderDescription } from '../../types/types';
import { startClassifiedTabSwitchListener, startSectionAddListening, startSectionDeleteListening, startSectionUpdateListening } from './classifiedListeners';

export const startNewfileListening = () => {
  startAppListening({
    actionCreator: newFile,
    effect: (action, listenerApi) => {
      const { headers, sections, classifieds, classifiedsOrder } = action.payload;

      const classifiedsWithErrors: { [key: string]: number } = {};
      for (const classifiedId in classifieds) {
        classifiedsWithErrors[classifiedId] = 0;
        const classified = classifieds[classifiedId];
        const result = runClassifiedValidators(classified);
        for (const sectionId in classified) {
          if (result[sectionId]) {
            const section = classified[sectionId];
            const { errors, warnings } = result[sectionId];
            section.errors = errors;
            section.warnings = warnings;
            classifiedsWithErrors[classifiedId] += errors.length + warnings.length;
          }
        }
      }

      listenerApi.dispatch(setHeaderData(headers));
      listenerApi.dispatch(setSections(sections));
      listenerApi.dispatch(setClassifieds(classifieds));
      const currentId = classifiedsOrder[0];
      const currentSections = Object.keys(classifieds[classifiedsOrder[0]]);
      listenerApi.dispatch(setTabsInfo({
        classifiedsOrder,
        classifiedsWithErrors,
        current: 0,
        currentId,
        currentSections,
      }));
      
      listenerApi.dispatch(setFileState({
        fileState: FileState.READY
      }));
      listenerApi.dispatch(clearAllListeners());
      // start other listeners
      startCloseFileListening();
      startExportFileListening();
      startSectionAddListening();
      startSectionUpdateListening();
      startSectionDeleteListening();
      startClassifiedTabSwitchListener();

      console.log(listenerApi.getState());
    }
  });
};

export const startCloseFileListening = () => {
  startAppListening({
    actionCreator: closeFile,
    effect: (_, listenerApi) => {
      listenerApi.dispatch(clearAllListeners());
      startNewfileListening();
    }
  });
};

export const startExportFileListening = () => {
  startAppListening({
    actionCreator: exportFile,
    effect: (_, listenerApi) => {
      const store = listenerApi.getState();
      const headers = store.headerStore as HeaderDescription;
      const sections = store.sectionsStore as string[];
      const classifieds = store.classifiedsStore as ClassifiedsDescription;
      const classifiedsOrder = store.tabsStore.classifiedsOrder;
      exportToCSV({
        headers,
        sections,
        classifieds,
        classifiedsOrder
      });
      listenerApi.dispatch(closeFile());
    }
  });
};
