import { shallowEqual, useSelector } from 'react-redux';
import { QRCode } from 'react-qr-svg';
import styled from 'styled-components';

import { RootState } from '../../store/store';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const QRCodePage = () => {
  const sections = useSelector((state: RootState) => state.sectionsStore, shallowEqual);
  // const classifieds = useSelector((state: RootState) => state.classifiedsStore, shallowEqual);

  return (
    <Container>
      <QRCode
        level='Q'
        width='90vh'
        value={JSON.stringify(sections)}
      />
    </Container>
  );
};

export default QRCodePage;
