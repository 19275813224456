import { Flex, FlexProps, Input, List, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { switchClassifiedTab } from '../../../store/actions';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import { TextProps } from 'antd/es/typography/Text';
import AddSectionModal from './AddsectionModal';

type SelectedClassified = {
  selected?: boolean;
};

const ClassifiedListContainer = styled(Flex) <FlexProps>`
  padding: 0 10px;
  height: 100%;
`;

const ClassifiedsList = styled(List<string>)`
  height: 90%;
  overflow: auto;
`;

const ListItem = styled(List.Item)`
  cursor: pointer;
`;

const ClaasifiedLabel = styled(Typography.Text)<TextProps & SelectedClassified>`
  ${({ selected }) => selected && 'color: blue'}
`;

const ClassifiedList = () => {
  const dispatch = useDispatch();
  const { classifiedsOrder, current } = useSelector((state: RootState) => state.tabsStore);
  const [filter, setFilter] = useState('');

  const onTabSeclected = (index: number) => {
    dispatch(switchClassifiedTab({ index }));
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const debouncedOnChange = useMemo(
    () => debounce(onChange, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, []);

  return (
    <ClassifiedListContainer vertical gap={10}>
      <AddSectionModal />
      <Input
        placeholder='Filter classifieds'
        onChange={debouncedOnChange}
      />
      <ClassifiedsList
        dataSource={filter === '' ? classifiedsOrder : classifiedsOrder.filter((item) => item.startsWith(filter))}
        renderItem={(item) => {
          const index = classifiedsOrder.indexOf(item);
          return (
            <ListItem
              onClick={() => {
                onTabSeclected(index);
              }}
            >
              <ClaasifiedLabel selected={index === current}>
                {item}
              </ClaasifiedLabel>
            </ListItem>
          );
        }}
      />
    </ClassifiedListContainer>
  );
};

export default ClassifiedList;
