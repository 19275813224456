export enum CategoryType {
  HOUSE = '1',
  OFFICE = '2',
  INDUSTRY = '3',
  COMMERCIAL = '4',
  APARTMENT = '5',
  LAND = '6',
  GARAGE = '7',
  OTHER = '8',
  HOMES_TO_BUILD = '9',
  HOUSE_GROUP = '11',
  APARTMENT_GROUP = '12',
}

export enum SubCategoryType {
  BUNGALOW = '1010',
  CHALET = '1011',
  CASTLE = '1012',
  FARMHOUSE = '1014',
  COUNTRY_COTTAGE = '1015',
  EXCEPTIONAL_PROPERTY = '1018',
  APARTMENT_BLOCK = '1020',
  MIXED_USE_BUILDING = '1021',
  HOUSE = '1024',
  TOWN_HOUSE = '1025',
  MANSION = '1026',
  VILLA = '1037',
  OTHER_PROPERTY = '1040',
  MANOR_HOUSE = '1041',
  PAVILION = '1051',
  HOUSE_GROUP = '1101',
  APARTMENT_GROUP = '1201',
  OFFICES = '2001',
  BUILDING = '2002',
  OFFICE_BLOCK = '2003',
  MIXED_USE_BUILDING_OFFICES = '2004',
  LARGE_TOWN_HOUSE = '2006',
  COMMERCIAL_VILLA = '2007',
  INDUSTRIAL_PREMISES = '3001',
  MIXED_USE_BUILDING_INDUSTRIES = '3002',
  WAREHOUSE = '3003',
  BUSINESS = '4001',
  COMMERCIAL_PREMISES = '4002',
  MIXED_USE_BUILDING_COMMERCIAL = '4003',
  HOTEL_RESTAURANT_CAFE = '4004',
  APARTMENT = '5001',
  GROUND_FLOOR = '5002',
  DUPLEX = '5003',
  TRIPLEX = '5004',
  FLAT_STUDIO = '5005',
  PENTHOUSE = '5006',
  LOFT = '5007',
  KOT = '5008',
  SERVICE_FLAT = '5009',
  BUILDING_LAND = '6001',
  RECREATIONAL_AREA = '6002',
  FOREST = '6003',
  FIELDS = '6004',
  MEADOW = '6005',
  ORCHARD = '6006',
  UNBUILDABLE_LAND = '6007',
  AGRICULTURAL_AREA = '6008',
  LAND = '6009',
  OUTDOOR_PARKING_SPACE = '7001',
  COVERED_PARKING_SPACE = '7002',
  LOCK_UP_GARAGE = '7003',
  LOCK_UP_PARKING = '7004',
  OTHER = '8000',
  PROVINCIAL_FARMHOUSE = '8001',
  MILL = '8002',
  BED_N_BREAKFAST = '8003',
  GITES = '8004',
  STATIC_CARAVAN = '8005',
  MOBILE_HOME = '8006',
  CAMPSITE = '8007',
  HOLIDAY_PARK = '8008',
  BARGE = '8009',
  HOTEL = '8010',
  OTHER_HOUSE = '8011',
  BOAT_CRUISE = '8012',
  MODEL_HOUSE = '9010',
  REFERENCE = '9020',
  SHOW_HOUSE = '9030',
}

export enum HeatingType {
  MAZOUT = 'M',
  GAZ = 'G',
  ELECTRIC = 'E',
  CHARBON = 'C',
  PELLET = 'P',
  WOOD = 'B'
}

export enum KitchenCode {
  INSTALLED = 'CE',
  SEMI_EQUIPED = 'SE',
  UNISTALLED = 'NE',
  HYPER_EQUIPED = 'XE',
  USA_SEMI_EQUIPED = 'AS',
  USA_INSTALLED = 'AC',
  USA_HYPER_EQUIPED = 'AX',
  USA_UNISTALLED = 'AN'
}

export enum Orientation {
  NORTH = 'N',
  SOUTH = 'S',
  EAST = 'E',
  NORTH_EAST = 'NE',
  SOUTH_EAST = 'SE',
  WEST = 'O',
  NORTH_WEST = 'NO',
  SOUTH_WEST = 'SO',
}

export enum AvailableAsOf {
  UPON_EXCHANGE_OF_DEEDS = 'AA',
  TO_BE_DEFINED = 'AD',
  AT_DELIVERY = 'AL',
  DEPENDING_ON_THE_TENANT = 'FL',
  IMMEDIATELY = 'IM'
}

export enum FloodRisk {
  RECOGNIZED_FLOOD_ZONE = '10',
  RECOGNIZED_N_CIRCUMSCRIBED_FLOOD_ZONE = '11',
  RECOGNIZED_N_CIRCUMSCRIBED_WATERSIDE_FLOOD_ZONE = '12',
  POSSIBLE_FLOOD_ZONE = '20',
  POSSIBLE_N_CIRCUMSCRIBED_FLOOD_ZONE = '21',
  POSSIBLE_N_CIRCUMSCRIBED_WATERSIDE_ZONE = '22',
  NOT_COMMUNICATED = '30',
  CIRCUMSCRIBED_FLOOD_ZONE = '31',
  CIRCUMSCRIBED_WATERSIDE_ZONE = '32',
  NON_FLOOD_ZONE = '40'
}

export enum EPC_SCORE {
  'NONE' = '0',
  'A++' = '1',
  'A+' = '2',
  'A' = '3',
  'B' = '4',
  'C' = '5',
  'D' = '6',
  'E' = '7',
  'F' = '8',
  'G' = '9',
  'A+_A++' = '10',
  'A_A++' = '11',
  'A_A+' = '12',
  'B_A++' = '13',
  'B_A+' = '14',
  'B_A' = '15',
  'C_A++' = '16',
  'C_A+' = '17',
  'C_A' = '18',
  'C_B' = '19',
  'D_A++' = '20',
  'D_A+' = '21',
  'D_A' = '22',
  'D_B' = '23',
  'D_C' = '24',
  'E_A++' = '25',
  'E_A+' = '26',
  'E_A' = '27',
  'E_B' = '28',
  'E_C' = '29',
  'E_D' = '30',
  'F_A++' = '31',
  'F_A+' = '32',
  'F_A' = '33',
  'F_B' = '34',
  'F_C' = '35',
  'F_D' = '36',
  'F_E' = '37',
  'G_A++' = '38',
  'G_A+' = '39',
  'G_A' = '40',
  'G_B' = '41',
  'G_C' = '42',
  'G_D' = '43',
  'G_E' = '44',
  'G_F' = '45',
}

export enum SectionNumberType {
  LISTING_NUMBER = '1',
  ACTION = '2',
  CATEGORY = '3',
  PRICE = '4',
  NUMBER_OF_ADRESS = '6',
  MONTHLY_RENTAL_SALE_PRICE = '7',
  ADRESS_WITHOUT_NUMBER = '8',
  ZIP_CODE = '9',
  LOCALITY = '10',
  DESCRIPTION_FR = '11',
  DESCRIPTION_EN = '12',
  DESCRIPTION_NL = '13',
  ATTIC_WITH_FIXED_STAIRS = '14',
  HEAT_PUMP = '15',
  PHOTOVOLTAIC_PANNELS = '16',
  SOLAR_PANNELS = '17',
  CPEB_CO2_EMISSIONS = '18',
  VAT = '19',
  TRANSACTION_TYPE = '20',
  COLLECTIVE_WATER_HEATER = '21',
  NUMBER_OF_ROOMS = '22',
  STATUS_OF_LISTING = '23',
  COUNTRY_CODE = '24',
  ENERGY_EFFICIENCY_CERTIFICATE_CODE = '25',
  AGENT_REFERENCE = '27',
  AVAILABILITY_DATE = '28', // not specified in document or it is mapped to section 35
  SUBCATEGORY = '30',
  ENVIRONMENT = '31',
  CADASTRAL_INCOME = '32',
  TOTAL_FLOOR_AREA = '33',
  YEAR_OF_CONSTRUCTION = '34',
  TOTAL_GROUND_AREA = '36',
  FRONT_FIELD = '37',
  BACK_FIELD = '38',
  FLAT_FIELD = '39',
  LAND_WIDTH = '43',
  LAND_DEPTH = '44',
  WOODEN_LAND = '45',
  BUILDING_PERMIT = '46',
  SEWER_CONNECTION = '47',
  ELECTRICITY_CONNECTION_POSSIBILITY = '48', // not parsed in ftp
  CONNECTION_TO_ELECTRICITY_GAS_WATER = '49',
  TYPE_OF_BUILDING = '50',
  TOTAL_CONSTRUCTION_SURFACE = '51',
  NUMBER_OF_FLOORS = '52',
  OBLIGATION_TOBUILD = '53',
  NUMBER_OF_HOUSINGS = '54',
  PROPERTY_CONDITION = '55',
  FURNISHED = '56',
  FACADE_WIDTH = '57',
  FACADE_COUNT = '58',
  FLOOR_NUMBER = '59',
  GARDEN_SURFACE = '60',
  HEATING_TYPE = '61',
  DOUBLE_GLAZING = '62',
  LIVING_ROOM_SURAFACE = '65',
  DINING_ROOM_SURAFACE = '66',
  OFFICE_SURAFACE = '67',
  FIREPLACE_NUMBER = '68',
  KITCHEN_SURFACE = '69',
  KITCHEN_CODE = '70',
  LAUNDRY_ROOM = '71',
  NUMBER_OF_BEDROOMS = '73',
  BEDROOM_SURFACE_1 = '74', 
  BEDROOM_SURFACE_2 = '75',
  BEDROOM_SURFACE_3 = '76',
  BEDROOM_SURFACE_4 = '77',
  BEDROOM_SURFACE_5 = '78',
  NUMBER_OF_BATHROOMS = '79',
  NUMBER_OF_SHOWERS = '80',
  NUMBER_OF_TOILETS = '81',
  ATTIC_SURFACE_NOT_CONVERTED = '82',
  ATTIC_SURFACE_CONVERTED = '83',
  //85 - specification and ftp parsing is different
  BASEMENT_SURFACE = '86',
  TERRASE_SURFACE = '87',
  //88 - not specified in document
  NUMBER_OF_CLOSED_BOXES = '89',
  NUMBER_OF_INSIDE_GARAGE = '90',
  NUMBER_OF_OUTSIDE_PLACES = '91',
  DOOR_PHONE = '92',
  ALARM = '93',
  CONCIERGE = '94',
  ELEVATOR = '95',
  ARMOURED_DOOR = '96',
  TELE_DISTRIBUTION = '97',
  ACCESS_FOR_DISABLED = '98',
  DISTANCE_CLOSE_BUS_STOP = '99', // not parsed in ftp
  DISTANCE_CLOSE_TRAM_STOP = '100', // not parsed in ftp
  DISTANCE_CLOSE_SUBWAY = '101', // not parsed in ftp
  DISTANCE_CLOSE_TRAIN_STATION = '102', // not parsed in ftp
  DISTANCE_CLOSE_SCHOOL = '103', // not parsed in ftp
  DISTANCE_CLOSE_SHOP = '104', // not parsed in ftp
  MONTHLY_COST = '108',
  GARDEN_ORIENTATION = '109',
  NEW_CONSTRUCTION = '116',
  INVESTMENT_PROPERTY = '117',
  HOLIDAY_RENTAL = '118',
  CERTIFICATE_OIL_TANK = '119',
  CERTIFICATE_ELECTRIC_INSTALLATION = '120',
  OCCUPANCY_RATE_PERCENTAGE = '121',
  MONTHLY_INCOME = '122',
  TITLE_FR = '123',
  TITLE_NL = '124',
  TITLE_EN = '125',
  ENERGETIC_PERFORMANCE = '126',
  FORMAT_L = '127',
  FORMAT_XL = '128',
  //129 - not parsed in ftp
  //130 - not parsed in ftp
  //131 - not parsed in ftp
  CERTIFICATE_AS_BUILT = '132',
  HAS_JACCUZI = '133', // not specified in document
  HAS_SAUNA = '134', // not specified in document
  ANNEX_COUNT = '135',
  E_LEVEL = '136',
  K_LEVEL = '137',
  CBEP_DESCRIPTION_FR = '138',
  CBEP_DESCRIPTION_NL = '139',
  CBEP_DESCRIPTION_EN = '140',
  ATTACHED_PDF_FILES = '145',
  PROMOTION_NAME = '146',
  PROMOTION_UNIT_COUNT = '147',
  PRE_EMPTIVE_RIGHT = '148',
  SUB_DIVISION_AUTHORIZATION = '149',
  LAST_USE_DESIGNATION = '150',
  PROMOTION_PROJECT_ID = '151',
  PHASE_CODE = '152',
  // 153 - not parsed in ftp
  IS_BREACHING_URBAN_PLANNING_REGULATION = '154',
  ESTIMATED_VALUE_OF_PROPERTY = '155',
  COMMERCIAL_PHONE = '156',
  COMMERCIAL_EMAIL = '158',
  COMMERCIAL_TITLE = '159', // not parsed in ftp
  COMMERCIAL_NAME = '160',
  PRICE_SOLD_RENTED = '161',
  PRICE_SOLD_RENTED_DISPLAY = '162',
  DATE_OF_SALE = '163',
  VIEWING_ARRANGEMENT_FR = '165',
  VIEWING_ARRANGEMENT_NL = '166',
  VIEWING_ARRANGEMENT_EN = '167',
  VENUE_EN = '168',
  VENUE_NL = '169',
  VENUE_FR = '170',
  //171 - not parsed in ftp
  //172 - not parsed in ftp
  //173 - not parsed in ftp
  MISCELLANEOUS_EQUIPMENT_EN = '174',
  MISCELLANEOUS_EQUIPMENT_NL = '175',
  MISCELLANEOUS_EQUIPMENT_FR = '176',
  INDOOR_MISCELLANEOUS_EQUIPMENT_EN = '177',
  INDOOR_MISCELLANEOUS_EQUIPMENT_NL = '178',
  INDOOR_MISCELLANEOUS_EQUIPMENT_FR = '179',
  URBAN_EN = '180',
  URBAN_NL = '181',
  URBAN_FR = '182',
  MONTHLY_ANNUITY = '183',
  INDEXED_ANNUITY = '184',
  JOINT_LIFE_ANNUITY = '185',
  IS_BARE_OWNERSHIP = '186', // true/false in ftp, dropdown in document (Type of annuity)
  NUMBER_OF_HEADS = '187',
  AGE_OF_VENDOR_1 = '188',
  AGE_OF_VENDOR_2 = '189',
  MAXIMUM_DURATION_FR = '190',
  MAXIMUM_DURATION_NL = '191',
  MAXIMUM_DURATION_EN = '192',
  UNIQUE_SESSION = '193',
  SMALL_PETS = '194',
  BIG_PETS = '195',
  SOLD_OR_RENTED = '196',
  MAKE_OFFER = '197',
  VIDEO_PHONE = '198',
  AVAILABLE_AS_OF = '199',
  PRICE_PER_SQM = '200',
  PRICE_GOODWILL = '201',
  VIRTUAL_TOUR_URL = '202',
  TRANSACTION_SUB_TYPE = '204',
  PROFESSION_SURFACE = '205',
  DRESSING_ROOM = '206',
  SWINMMING_POOL = '208',
  LIVING_ROOM = '210',
  DINING_ROOM = '211',
  TERRACE = '212',
  ATTIC = '213',
  BASEMENT = '214',
  OFFICE = '215',
  SHOWROOM_SURFACE = '216',
  MAX_AVAILABLE_HEIGHT = '217',
  AVAILABLE_SURFACE = '218',
  NUMBER_OF_INDIVIDUAL_INSIDE_PARKINGS = '219',
  NUMBER_OF_ADDITIONAL_INDIVIDUAL_PARKINGS = '220',
  SHOP_WINDOW_WIDTH = '221',
  DETAILED_PLAN = '222',
  SITUATION_PLAN = '223',
  YEARLY_RENTAL_PRICE = '224',
  // 225 - not specified in document (mix of sections 180 - 182) urbanAll
  HIGHER_BID_RIGHT = '228',
  HIGHER_BID_PRIME = '229',
  HIGHER_BID_DATE_LIMIT = '230',
  RESERVE_PRICE = '231',
  SESSION_PRICE = '233',
  COMPLEMENTARY_SESSION_PRICE = '234',
  SESSION_DATE_HOUR = '235',
  COMPLEMENTARY_SESSION_DATE_HOUR = '237',
  NEXT_SESSION_PLACE = '238',
  // 239 not specified in document (mix of sections 165 - 167) viewing arrangements
  DESCRIPTION_ADDITIONAL_FR = '241',
  DESCRIPTION_ADDITIONAL_NL = '242',
  DESCRIPTION_ADDITIONAL_EN = '243',
  NAME_OF_RESIDENCE = '246',
  NEIGHBORHOOD = '247',
  VIEW_ON_SEA = '248',
  AIR_CONDITIONER = '267',
  INTERNET_ACCESS = '268',
  FLOOD_ZONE_TYPE = '289',
  TERRACE_ORIENTATION = '290',
  NUMBER_OF_ACCESS_DOORS = '293',
  NUMBER_OF_SECTIONAL_DOORS = '294',
  NUMBER_OF_SLIDING_DOORS = '295',
  NUMBER_OF_LOADING_DOCKS = '296',
  NUMBER_OF_COVERED_DOCKS = '297',
  NUMBER_OF_DOCKS_WITH_LEVERS = '298',
  ARCHITECT = '299',
  STABILITY_EGINEER = '300',
  SAFETY_COORDINATOR = '301',
  GROUND_STUDY = '302',
  KITCHEN_INCLUDED = '303',
  OUTDOOR_ARANGEMENT = '304',
  BLOWER_DOOR_TEST = '305',
  STRUCTURAL_WORK_PRICE = '306',
  CONSTRUCTION_PERIOD = '307',
  HOUSES_TO_BUILD_TYPE = '308',
  HOUSES_TO_BUILD_ARCHITECTURE = '309',
  HOUSES_TO_BUILD_ENERGY_PERFORMANCE = '310',
  CLEARANCE_HEIGHT = '311',
  ON_THE_SPOTLIGHT_FORMAT = '313',
  THEORETICAL_ENERGY_CONSUMTION_YEARLY = '314',
  ENERGY_CLASS_LABEL = '315',
  DISPLAY_ON_BD = '318',
  DISPLAY_ON_IMMOWEB = '319',
  DISPLAY_PRICE_BD = '320',
  DISPLAY_MODE = '321',
  DISPLAY_MODE_SOLD_PERCENTAGE = '322',
  MANUAL_SOLD_PERCENTAGE = '323',
  REMARKETING_URL_FR = '325',
  REMARKETING_URL_NL = '326',
  REMARKETING_URL_EN = '327',
  P_SCORE = '328',
  G_SCORE = '329',
  NEW_BUILDING_LAND_PRICE = '330',
  NEW_BUILDING_VAT = '331',
  NEW_BUILDING_TAXES = '332',
  NEW_BUILDING_TOTAL_PRICE = '333',
  ASBESTOS_CERTIFICATE = '334',
}
