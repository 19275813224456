import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Flex, Input, Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { RootState } from '../../store/store';
import { HeaderDescription } from '../../types/types';
import { updateHeaderData } from '../../store/slices/headerSlice';

const FilenameInput = styled(Input)`
  // max-width: 375px;
`;

const SoftwareNameInput = styled(Input)`
  max-width: 250px;
`;

const SoftwareIDInput = styled(Input)`
  max-width: 170px;
`;

const pcOrMacValues = [
  { value: 'PC', label: 'PC' },
  { value: 'WIN', label: 'WIN' },
  { value: 'MAC', label: 'Mac' },
];

const actionValues = [
  { value: 'CREATE', label: 'Create' },
  { value: 'UPDATE', label: 'Update' },
  { value: 'RESET', label: 'Reset' },
];

const FileHeader: React.FC = () => {
  const headerData = useSelector((state: RootState) => {
    return state.headerStore as HeaderDescription;
  });

  const dispatch = useDispatch();

  const onChange = (value: string, key: keyof HeaderDescription) => {
    dispatch(updateHeaderData({ key, value }));
  };

  if (!headerData) {
    return null;
  }

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={8} className='gutter-row'>
        <FilenameInput
          addonBefore='File Name:'
          placeholder='File name'
          value={headerData.fileName}
          onChange={(event) => {
            onChange(event.target.value, 'fileName');
          }}
          suffix={
            <Tooltip title="Extra information">
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          }
        />
      </Col>
      <Col span={8} className='gutter-row'>
        <Flex gap={5}>
          <SoftwareNameInput
            addonBefore='Software Name:'
            placeholder='Software Name'
            value={headerData.softwareName}
            onChange={(event) => {
              onChange(event.target.value, 'softwareName');
            }}
          />
          <SoftwareIDInput
            addonBefore='Software ID:'
            placeholder='Software ID'
            value={headerData.softwareId}
            onChange={(event) => {
              onChange(event.target.value, 'softwareId');
            }}
          />
        </Flex>
      </Col>
      <Col span={4} className='gutter-row'>
        <Flex gap={5}>
          <Select
            options={pcOrMacValues}
            onChange={(val) => {
              onChange(val, 'pcOrMac');
            }}
            value={headerData.pcOrMac}
          />
          <Select
            options={actionValues}
            onChange={(val) => {
              onChange(val, 'action');
            }}
            value={headerData.action}
          />
        </Flex>
      </Col>
    </Row>
  );
};

export default FileHeader;
