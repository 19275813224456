import { Button } from 'antd';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { closeFile } from '../../store/actions';
import routes from '../../routes';

const CloseFileButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    dispatch(closeFile());
    navigate(routes.index);
  }, [closeFile, dispatch]);

  return <Button onClick={handleClick}>Close</Button>;
};

export default CloseFileButton;
