import styled from 'styled-components';
import SectionsListView from './SectionsListView';
import { Flex, FlexProps } from 'antd';

const Container = styled(Flex)<FlexProps>`
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const SectionView = () => {
  return (
    <Container
      vertical
      gap={15}
    >
      <SectionsListView />
    </Container>
  );
};

export default SectionView;
