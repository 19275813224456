import { CategoryType, SectionNumberType, SubCategoryType } from '../types/classifiedEnums';
import { Mandatory, MandatoryForValues, RegexpCheck, RegexpCheckForValues, RequiresSectionValue, RequiresSectionValueForValues } from '../types/validators';

type ValidatorConfig = {
  [key in SectionNumberType]?: {
    regexpCheck?: RegexpCheck,
    regexpCheckForValues?: RegexpCheckForValues,
    mandatory?: Mandatory,
    mandatoryForValues?: MandatoryForValues,
    requiresSectionValue?: RequiresSectionValue,
    requiresSectionValueForValues?: RequiresSectionValueForValues,
    minValue?: number,
    maxValue?: number,
    minLength?: number,
    maxLength?: number,
  }
}

const mandatorySections: string[] = [
  SectionNumberType.LISTING_NUMBER,
  SectionNumberType.CATEGORY,
  SectionNumberType.TRANSACTION_TYPE,
  SectionNumberType.STATUS_OF_LISTING,
];

const validatorsConfig: ValidatorConfig = {
  [SectionNumberType.LISTING_NUMBER]: {
    regexpCheck: {
      pattern: '^[1-9][0-9a-zA-Z]{0,7}$',
      type: 'error',
    },
    maxLength: 8,
  },
  [SectionNumberType.CATEGORY]: {
    mandatoryForValues: {
      [CategoryType.HOUSE]: [SectionNumberType.NUMBER_OF_BEDROOMS],
      [CategoryType.APARTMENT]: [SectionNumberType.NUMBER_OF_BEDROOMS],
      [CategoryType.HOMES_TO_BUILD]: [SectionNumberType.FACADE_COUNT],
    },
  },
  [SectionNumberType.NUMBER_OF_ADRESS]: {
    regexpCheckForValues: {
      [SectionNumberType.CATEGORY]: {
        [CategoryType.APARTMENT]: {
          pattern: '^[1-9][0-9]*,[1-9][0-9]*$',
          type: 'error',
        },
      },
    },
  },

  [SectionNumberType.ATTIC]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.ATTIC_WITH_FIXED_STAIRS]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.ATTIC_SURFACE_NOT_CONVERTED]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.ATTIC_SURFACE_CONVERTED]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },

  [SectionNumberType.SUBCATEGORY]: {
    requiresSectionValueForValues: {
      [SectionNumberType.CATEGORY]: {
        [CategoryType.HOUSE]: [
          SubCategoryType.BUNGALOW,
          SubCategoryType.CHALET,
          SubCategoryType.CASTLE,
          SubCategoryType.FARMHOUSE,
          SubCategoryType.COUNTRY_COTTAGE,
          SubCategoryType.EXCEPTIONAL_PROPERTY,
          SubCategoryType.APARTMENT_BLOCK,
          SubCategoryType.MIXED_USE_BUILDING,
          SubCategoryType.HOUSE,
          SubCategoryType.TOWN_HOUSE,
          SubCategoryType.MANSION,
          SubCategoryType.VILLA,
          SubCategoryType.OTHER_PROPERTY,
          SubCategoryType.MANOR_HOUSE,
          SubCategoryType.PAVILION
        ],
        [CategoryType.OFFICE]: [
          SubCategoryType.OFFICES,
          SubCategoryType.BUILDING,
          SubCategoryType.OFFICE_BLOCK,
          SubCategoryType.MIXED_USE_BUILDING_OFFICES,
          SubCategoryType.LARGE_TOWN_HOUSE,
          SubCategoryType.COMMERCIAL_VILLA,
        ],
        [CategoryType.INDUSTRY]: [
          SubCategoryType.INDUSTRIAL_PREMISES,
          SubCategoryType.MIXED_USE_BUILDING_INDUSTRIES,
          SubCategoryType.WAREHOUSE
        ],
        [CategoryType.COMMERCIAL]: [
          SubCategoryType.BUSINESS,
          SubCategoryType.COMMERCIAL_PREMISES,
          SubCategoryType.MIXED_USE_BUILDING_COMMERCIAL,
          SubCategoryType.HOTEL_RESTAURANT_CAFE
        ],
        [CategoryType.APARTMENT]: [
          SubCategoryType.APARTMENT,
          SubCategoryType.GROUND_FLOOR,
          SubCategoryType.DUPLEX,
          SubCategoryType.TRIPLEX,
          SubCategoryType.FLAT_STUDIO,
          SubCategoryType.PENTHOUSE,
          SubCategoryType.LOFT,
          SubCategoryType.KOT,
          SubCategoryType.SERVICE_FLAT,
        ],
        [CategoryType.LAND]: [
          SubCategoryType.BUILDING_LAND,
          SubCategoryType.RECREATIONAL_AREA,
          SubCategoryType.FOREST,
          SubCategoryType.FIELDS,
          SubCategoryType.MEADOW,
          SubCategoryType.ORCHARD,
          SubCategoryType.UNBUILDABLE_LAND,
          SubCategoryType.AGRICULTURAL_AREA,
          SubCategoryType.LAND,
        ],
        [CategoryType.GARAGE]: [
          SubCategoryType.OUTDOOR_PARKING_SPACE,
          SubCategoryType.COVERED_PARKING_SPACE,
          SubCategoryType.LOCK_UP_GARAGE,
          SubCategoryType.LOCK_UP_PARKING,
        ],
        [CategoryType.OTHER]: [
          SubCategoryType.OTHER,
          SubCategoryType.PROVINCIAL_FARMHOUSE,
          SubCategoryType.MILL,
          SubCategoryType.BED_N_BREAKFAST,
          SubCategoryType.GITES,
          SubCategoryType.STATIC_CARAVAN,
          SubCategoryType.MOBILE_HOME,
          SubCategoryType.CAMPSITE,
          SubCategoryType.HOLIDAY_PARK,
          SubCategoryType.BARGE,
          SubCategoryType.HOTEL,
          SubCategoryType.OTHER_HOUSE,
          SubCategoryType.BOAT_CRUISE,
        ],
        [CategoryType.HOMES_TO_BUILD]: [
          SubCategoryType.MODEL_HOUSE,
          SubCategoryType.REFERENCE,
          SubCategoryType.SHOW_HOUSE,
        ],
        [CategoryType.HOUSE_GROUP]: [SubCategoryType.HOUSE_GROUP],
        [CategoryType.APARTMENT_GROUP]: [SubCategoryType.APARTMENT_GROUP],
      }
    }
  },

  [SectionNumberType.NUMBER_OF_BEDROOMS]: { //TODO Add dependency to category and sub-category sections
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.DRESSING_ROOM]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.BEDROOM_SURFACE_1]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.BEDROOM_SURFACE_2]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.BEDROOM_SURFACE_3]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.BEDROOM_SURFACE_4]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.BEDROOM_SURFACE_5]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },

  [SectionNumberType.NUMBER_OF_BATHROOMS]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },

  [SectionNumberType.BASEMENT_SURFACE]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.BASEMENT]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },

  [SectionNumberType.ANNEX_COUNT]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.NUMBER_OF_FLOORS]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.FACADE_WIDTH]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.GARAGE,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.FACADE_COUNT]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.PROPERTY_CONDITION]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.GARAGE,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    }
  },
  [SectionNumberType.YEAR_OF_CONSTRUCTION]: {
    requiresSectionValue: {
      [SectionNumberType.CATEGORY]: {
        acceptedValues: [
          CategoryType.HOUSE,
          CategoryType.OFFICE,
          CategoryType.COMMERCIAL,
          CategoryType.INDUSTRY,
          CategoryType.APARTMENT,
          CategoryType.GARAGE,
          CategoryType.OTHER,
          CategoryType.HOMES_TO_BUILD,
        ],
        type: 'warning'
      }
    },
    minValue: 1753,
  },

};

export { validatorsConfig, mandatorySections };