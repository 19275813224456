import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import SectionsListItemView from './SectionsListItemView';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';

const SectionsListView = () => {
  const [form] = useForm();
  const currentSections = useSelector((state: RootState) => state.tabsStore.currentSections, shallowEqual);

  return (
    <Form
      form={form}
      layout='vertical'
      size='large'
    >
      {currentSections.map((sectionId) => <SectionsListItemView sectionId={sectionId} />)}
    </Form>
  );
};

export default SectionsListView;
