import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

import { ClassifiedsPage, ErrorPage, JsonViewPage, QRCodePage, Root, TablePage, TextPage, WelcomePage } from './pages';
import r from './routes';

const routes: RouteObject[] = [
  {
    path: r.index,
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: r.index,
        element: <WelcomePage />,
      },
      {
        path: r.sectionsView,
        element: <ClassifiedsPage />
      },
      {
        path: r.tableView,
        element: <TablePage />
      },
      {
        path: r.textView,
        element: <TextPage />
      },
      {
        path: r.jsonView,
        element: <JsonViewPage />
      },
      {
        path: r.qrcodeView,
        element: <QRCodePage />
      }
    ],
  }
];

const router = createBrowserRouter(routes);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
