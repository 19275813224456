import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Form, Input, Row, Select, Tooltip, Typography } from 'antd';
import { SectionDescription } from '../../../types/types';
import { SectionNumberType } from '../../../types/classifiedEnums';
import { sectionsConfig } from '../../../sections/sectionsConfig';

type SectionListItemProps = {
  sectionId: string,
  data: SectionDescription,
  onChange: (value: string) => void,
  onDelete: () => void
};

type SectionTextProps = {
  value: string,
  maxLength?: number,
  onChange: (value: string) => void,
};

type SectionNumberProps = {
  value: string,
  minValue?: number,
  maxValue?: number,
  stepValue?: number,
  maxLength?: number,
  onChange: (value: string) => void,
};

type SectionSelectProps = {
  value: string,
  values: { value: string, label: string }[],
  onChange: (value: string) => void,
}

const SectionTextInput = ({ value, maxLength, onChange }: SectionTextProps) => {
  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      showCount={maxLength !== undefined}
      maxLength={maxLength}
    />
  );
};

const SectionNumberInput = (props: SectionNumberProps) => {
  const {
    value,
    minValue,
    maxValue,
    stepValue,
    maxLength,
    onChange
  } = props;
  return (
    <Input
      style={{
        'width': '100%'
      }}
      type='number'
      value={value}
      onChange={(e) => onChange(e.target.value)}
      maxLength={maxLength}
      showCount={maxLength !== undefined}
      min={minValue?.toString()}
      max={maxValue?.toString()}
      step={stepValue?.toString()}
    />
  );
};

const SectionMultilineInput = ({ value, maxLength, onChange }: SectionTextProps) => {
  return (
    <Input.TextArea
      autoSize
      maxLength={maxLength}
      value={value}
      showCount
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

const SectionSelectInput = ({value, values, onChange}: SectionSelectProps) => {
  return (
    <Select
      options={values}
      value={value}
      onChange={onChange}
      size='large'
    />
  );
};

const SectionListItem = ({ sectionId, data, onChange, onDelete }: SectionListItemProps) => {
  let sectionLabel = sectionId;
  let tooltip: JSX.Element[] | undefined;
  let element: JSX.Element;
  if (Object.values<string>(SectionNumberType).includes(sectionId)) {
    const uiData = sectionsConfig[sectionId as SectionNumberType];
    sectionLabel = uiData.label;
    if (uiData.hint) {
      tooltip = Array.isArray(uiData.hint)
        ? uiData.hint.map((hint) => <Typography.Text color='white'>{hint}</Typography.Text>)
        : [<Typography.Text>{uiData.hint}</Typography.Text>];
    }
    switch (uiData.uiType) {
      case 'dropdown': {
        element = SectionSelectInput({
          value: data.value, 
          values: uiData.values,
          onChange,
        });
        break;
      }
      case 'multilineInput': {
        element = SectionMultilineInput({
          value: data.value,
          onChange,
          maxLength: uiData.maxLength,
        });
        break;
      }
      case 'numberInput': {
        element = SectionNumberInput({
          value: data.value,
          onChange,
          maxLength: uiData.maxLength,
          minValue: uiData.minValue,
          maxValue: uiData.maxValue,
          stepValue: uiData.stepValue,
        });
        break;
      }
      default: {
        element = SectionTextInput({
          value: data.value,
          onChange,
          maxLength: uiData.maxLength,
        });
        break;
      }
    }
  } else {
    element = element = SectionTextInput({
      value: data.value,
      onChange,
    });
  }
  return (
    <Form.Item label={sectionLabel}>
      <Row gutter={10} align='middle' justify='center'>
        <Col xs={22}>
          {element}
        </Col>
        <Col xs={1}>
          {
            tooltip &&
            <Tooltip
              overlayStyle={{
                maxWidth: '360px'
              }}
              title={(
                <Flex vertical>
                  {tooltip}
                </Flex>
              )}
              placement='left'
              color='white'
            >
              <Button
                icon={<InfoCircleOutlined />}
                shape='circle'
                type='text'
              />
            </Tooltip>
          }
        </Col>
        <Col xs={1}>
          <Button
            icon={<DeleteOutlined />}
            onClick={onDelete}
            danger
            shape='circle'
            type='text'
          />
        </Col>
      </Row>
    </Form.Item>
  );
};

export default SectionListItem;
