import { Button } from 'antd';
import { useDispatch } from 'react-redux';

import { exportFile } from '../../store/actions';
import { useCallback } from 'react';

const ExportFileButton = () => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(exportFile());
  }, [exportFile, dispatch]);

  return <Button onClick={handleClick}>Export</Button>;
};

export default ExportFileButton;
