import { SectionNumberType } from '../types/classifiedEnums';
import { SectionConvertorDescription } from '../types/convertors';

type SectionsConverterConfigType = {
  [key in SectionNumberType]?: SectionConvertorDescription
};

const convertorsConfig: SectionsConverterConfigType = {
  [SectionNumberType.ATTIC_WITH_FIXED_STAIRS]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.HEAT_PUMP]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.PHOTOVOLTAIC_PANNELS]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.SOLAR_PANNELS]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.CPEB_CO2_EMISSIONS]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.VAT]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.FRONT_FIELD]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.BACK_FIELD]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.FLAT_FIELD]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.WOODEN_LAND]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.BUILDING_PERMIT]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    otherValues: ['not communicated'],
    positiveValue: 'Y',
    negativeValue: 'N',
    otherValue: 'not communicated',
  },
  [SectionNumberType.SEWER_CONNECTION]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.ELECTRICITY_CONNECTION_POSSIBILITY]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.CONNECTION_TO_ELECTRICITY_GAS_WATER]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.OBLIGATION_TOBUILD]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.FURNISHED]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.DRESSING_ROOM]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
  [SectionNumberType.ATTIC]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    otherValues: ['i'],
    positiveValue: 'Y',
    negativeValue: 'N',
    otherValue: 'I',
  },
  [SectionNumberType.BASEMENT]: {
    positiveValues: ['vrai', 'true', '1', 'yes', 'y'],
    negativeValues: ['faux', 'false', '0', 'no', 'n'],
    positiveValue: 'Y',
    negativeValue: 'N',
  },
};

export { convertorsConfig };
