import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useCallback } from 'react';
import { removeSectionFromClassified, updateSectionInClassified } from '../../../store/actions';
import SectionListItem from '../components/SectionListItem';

type SectionsListItemViewProps = {
  sectionId: string,
};

const SectionsListItemView = ({ sectionId }: SectionsListItemViewProps) => {
  const classifiedId = useSelector((store: RootState) => store.tabsStore.currentId, shallowEqual);
  const data = useSelector((store: RootState) => store.classifiedsStore[classifiedId][sectionId], shallowEqual);
  const dispatch = useDispatch();

  const onChange = useCallback((value: string) => {
    dispatch(updateSectionInClassified({ classifiedId, sectionId, value }));
  }, [sectionId]);

  const onDelete = useCallback(() => {
    dispatch(removeSectionFromClassified({ classifiedId, sectionId }));
  }, [sectionId]);

  return (
    <SectionListItem
      sectionId={sectionId}
      data={data}
      onChange={onChange}
      onDelete={onDelete}
    />
  );
};

export default SectionsListItemView;
