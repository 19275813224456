import Sider, { SiderProps } from 'antd/es/layout/Sider';
import ClassifiedList from './ClassifiedList';
import styled from 'styled-components';

const ClassifiedSectionsSider = styled(Sider)<SiderProps>`
  overflow: auto;
  height: 100%;
`;

const ClassifiedsSider = () => {
  return (
    <ClassifiedSectionsSider>
      <ClassifiedList />
    </ClassifiedSectionsSider>
  );
};

export default ClassifiedsSider;
