import { createSlice } from '@reduxjs/toolkit';
import { closeFile } from '../actions';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum FileState {
  PARSING,
  READY,
  ERROR,
  PENDING,
}

type FileStateInitialState = {
  fileState: FileState,
  error?: string,
};

const fileStateSlice = createSlice({
  name: 'fileReady',
  initialState: {
    fileState: FileState.PENDING,
  } as FileStateInitialState,
  reducers: {
    setFileState: (_, action: PayloadAction<FileStateInitialState>) => {
      return action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(closeFile, (state) => {
      state.fileState = FileState.PENDING;
    });
  },
});

export const { setFileState } = fileStateSlice.actions;

export default fileStateSlice.reducer;
