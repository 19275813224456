import { SectionNumberType } from '../types/classifiedEnums';
import { SectionConvertorDescription } from '../types/convertors';
import { convertorsConfig } from './convertorsConfig';

const convertSectionValue = (id: string, value: string): string => {
  let val = value;
  if(Object.hasOwn(convertorsConfig, id)) {
    const convertor = convertorsConfig[id as SectionNumberType] as SectionConvertorDescription;

    if (convertor.positiveValues.includes(value.toLowerCase())) {
      val = convertor.positiveValue;
    } else if (convertor.negativeValues.includes(value.toLowerCase())) {
      val = convertor.negativeValue;
    } else if (convertor.otherValues && convertor.otherValues.includes(value.toLowerCase())) {
      val = convertor.otherValue ?? value;
    }
  }

  return val;
};

export { convertSectionValue };
