import { ClassifiedsDescription, FileDescription } from '../../types/types';

const convertClassifiedsToText = (sections: string[], classifieds: ClassifiedsDescription, classifiedsOrder: string[]) => {
  const converted: { [key: string]: string } = {};
  sections.forEach(section => {
    for (const classified in classifieds) {
      let current = converted[classified];
      current = current ? current + '\t' : '';
      if (classifieds[classified][section]?.value) {
        current += classifieds[classified][section].value;
      }
      converted[classified] = current;
    }
  });
  return classifiedsOrder.reduce((previousValue, currentValue) => {
    const result = `${previousValue}${previousValue.length === 0 ? '' : '\n'}${converted[currentValue]}`;
    return result;
  }, '');
};

const exportToCSV = (fileDescription: FileDescription) => {
  const headers = fileDescription.headers;
  const csv = headers.softwareName + '\n' +
    headers.pcOrMac + '\n' +
    headers.softwareId + '\n' +
    headers.apiVersion + '\n' +
    headers.action + '\n' +
    'START \n' +
    fileDescription.sections.join('\t') + '\n' +
    convertClassifiedsToText(fileDescription.sections, fileDescription.classifieds, fileDescription.classifiedsOrder) + '\n' +
    'EOF' + fileDescription.classifiedsOrder.length;

  console.log(csv);
  const link = document.createElement('a');
  const file = new Blob([csv], { type: 'text/plain' });
  link.href = URL.createObjectURL(file);
  link.download = headers.fileName;
  link.click();
  URL.revokeObjectURL(link.href);
};

export { exportToCSV };