import { useState } from 'react';
// import { shallowEqual, useSelector } from 'react-redux';
// import { RootState } from '../../../store/store';
import { Button, Col, Form, Input, Layout, Modal, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ClassifiedDescription } from '../../../types/types';
import SectionListItem from '../components/SectionListItem';

const AddSectionModal = () => {
  // const currentSections = useSelector((state: RootState) => state.tabsStore.currentSections, shallowEqual);
  const [open, setOpen] = useState(false);
  const [form] = useForm();
  const [newId, setNewId] = useState<string>('');
  const [newSectionIds, setNewSectionIds] = useState<string[]>([]);
  const [newSections, setNewSections] = useState<ClassifiedDescription>({});
  // const [newSectionValue, setNewSectionValue] = useState<string>('');
  // const [newSectionError, setNewSectionError] = useState<string | undefined>();

  const clearModal = () => {
    setNewId('');
    setNewSectionIds([]);
    setNewSections({});
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onOk = () => {
    //TODO: add dhandler for adding new sections
    setOpen(false);
  };

  return (
    <>
      <Button type='primary' onClick={() => setOpen(true)}>Add sections</Button>
      <Modal
        title='Add new sections'
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        afterClose={clearModal}
        width='80vw'
        okText='Add'
        okButtonProps={{
          disabled: newSectionIds.length === 0
        }}
      >
        <Layout
          style={{
            padding: '20px',
            borderRadius: '10px'
          }}
        >
          <Row gutter={24}>
            <Col>
              <Input
                type='number'
                addonBefore='Section number'
                value={newId}
                onChange={(e) => {
                  setNewId(e.target.value);
                }}
              />
            </Col>
            <Col>
              <Button
                type='primary'
                onClick={() => {
                  // add checks for existing sections
                  setNewSectionIds([newId, ...newSectionIds]);
                  setNewSections({
                    ...newSections,
                    [newId]: {
                      value: '',
                      errors: [],
                      warnings: []
                    }
                  });
                  setNewId('');
                }}
              >
                Add section
              </Button>
            </Col>
          </Row>
          <Form
            style={{
              padding: '20px 15px',
              marginLeft: '-15px',
              height: '60vh',
              maxWidth: '100%',
              overflowY: 'auto'
            }}
            form={form}
            layout='vertical'
            title='Added sections:'
            size='large'
          >
            {newSectionIds.map((sectionId) => {
              const data = newSections[sectionId];
              return (
                <SectionListItem
                  sectionId={sectionId}
                  data={data}
                  onChange={(value) => {
                    const next = { ...newSections };
                    next[sectionId].value = value;
                    setNewSections(next);
                  }}
                  onDelete={() => {
                    const n = [...newSectionIds];
                    console.log(n);
                    n.splice(newSectionIds.indexOf(sectionId), 1);
                    console.log(n);
                    setNewSectionIds(n);
                    const next = { ...newSections };
                    delete next[sectionId];
                    setNewSections(next);
                  }}
                />
              );
            }
            )}
            {newSectionIds.length === 0 && <Typography.Text>No sections selected</Typography.Text>}
          </Form>
        </Layout>
      </Modal>
    </>
  );
};

export default AddSectionModal;
