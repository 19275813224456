import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { closeFile } from '../actions';

type TabsInitialState = {
  current: number,
  currentId: string,
  currentSections: string[],
  classifiedsOrder: string[],
  classifiedsWithErrors: {
    [key: string]: number
  },
};

type SetCurrentTabPayload = {
  index: number,
  id: string,
  sections: string[],
};

type UpdateErrorCountPayload = {
  classifiedId: string,
  errorCount: number,
};

type UpdateCurrentSectionsPayload = string[];

const tabsInitialState: TabsInitialState = {
  current: -1,
  currentId: '',
  currentSections: [],
  classifiedsOrder: [],
  classifiedsWithErrors: {},
};

const tabsSlice = createSlice({
  name: 'tabsData',
  initialState: { ...tabsInitialState },
  reducers: {
    setTabsInfo: (_, action: PayloadAction<TabsInitialState>) => {
      return action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<SetCurrentTabPayload>) => {
      const {index, id, sections} = action.payload;
      state.current = index;
      state.currentId = id;
      state.currentSections = sections;
    },
    updateErrorCount: (state, action: PayloadAction<UpdateErrorCountPayload>) => {
      const { classifiedId, errorCount } = action.payload;
      if (Object.hasOwn(state.classifiedsWithErrors, classifiedId)) {
        state.classifiedsWithErrors[classifiedId] = errorCount;
      }
    },
    updateCurrentSections: (state, action: PayloadAction<UpdateCurrentSectionsPayload>) => {
      state.currentSections = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(closeFile, () => {
      return { ...tabsInitialState };
    });
  },
});

export const {
  setTabsInfo,
  setCurrentTab,
  updateErrorCount,
  updateCurrentSections,
} = tabsSlice.actions;

export default tabsSlice.reducer;
