import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import FileHeader from '../../components/classified/FileHeader';
import { sectionsConfig } from '../../sections/sectionsConfig';
import { RootState } from '../../store/store';
import { ClassifiedDescription, SectionDescription } from '../../types/types';
import { useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { SectionNumberType } from '../../types/classifiedEnums';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
} from 'ag-grid-community';
import { switchClassifiedTab } from '../../store/actions';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  width: 100%;
  height: 100%;
  // height: calc(100vh - 700px);
`;

const ClassifiedTableContainer = styled.div`
  margin-top: 16px;
  height: 100%;
`;

const TablePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sections = useSelector((state: RootState) => state.sectionsStore, shallowEqual);
  const classifiedsOrder = useSelector((state: RootState) => state.tabsStore.classifiedsOrder, shallowEqual);
  const classifieds = useSelector((state: RootState) => state.classifiedsStore, shallowEqual);

  const data = useMemo(() => {
    return classifiedsOrder.map((classifiedId) => {
      return classifieds[classifiedId];
    });
  }, [classifieds, classifiedsOrder]);

  const columns = useMemo(() => {
    return sections.map((sectionId) => {
      const columnDef: ColDef<ClassifiedDescription, SectionDescription> = {
        headerName: sectionId,
        field: sectionId,
        ...(sectionId === '1' ? { pinned: 'left' } : {}),
        valueFormatter: (p) => p.value?.value ?? ''
      };
      if (Object.values<string>(SectionNumberType).includes(sectionId)) {
        const uiData = sectionsConfig[sectionId as SectionNumberType];
        columnDef.headerName = uiData.label;
      }
      return columnDef;
    });
  }, [sections]);

  return (
    <Container>
      <FileHeader />
      <ClassifiedTableContainer className='ag-theme-quartz'>
        <AgGridReact<ClassifiedDescription>
          rowData={data}
          columnDefs={columns}
          onCellClicked={(ev) => {
            console.log(ev);
            if (ev.column.getColId() === '1') {
              const index = classifiedsOrder.indexOf(ev.value.value);
              dispatch(switchClassifiedTab({ index }));
              navigate(routes.sectionsView);
            }
          }}
        />
      </ClassifiedTableContainer>
    </Container>
  );
};

export default TablePage;
