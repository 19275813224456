const appConfig = {
  content: {
    footerText: `FTP UI Tool ©${new Date().getFullYear()} Created by Igor&Max (Endava)`,
  },
  settings: {
    // provide values for default settings
  }
};

export default appConfig;
