import { createAction } from '@reduxjs/toolkit';
import { FileDescription } from '../types/types';

type AddSectionPayload = {
  classifiedId: string,
  sectionId: string,
  value?: string,
};

type UpdateSectionPayload = {
  classifiedId: string,
  sectionId: string,
  value: string,
};

type RemoveSectionPayload = {
  classifiedId: string,
  sectionId: string,
};

type SwitchClassifiedTabPayload = {
  index: number
};

export const closeFile = createAction<undefined>('closeFile');

export const exportFile = createAction<undefined>('exportFile');

export const newFile = createAction<FileDescription>('newFile');

export const addSectionToClassified = createAction<AddSectionPayload>('addSectionToClassified');

export const updateSectionInClassified = createAction<UpdateSectionPayload>('updateSectionInClassified');

export const removeSectionFromClassified = createAction<RemoveSectionPayload>('removeSectionFromClassified');

export const switchClassifiedTab = createAction<SwitchClassifiedTabPayload>('switchClassifiedTab');
