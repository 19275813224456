import { configureStore } from '@reduxjs/toolkit';
import headerSlice from './slices/headerSlice';
import classifiedsSlice from './slices/classifiedsSlice';
import sectionsSlice from './slices/sectionsSlice';
import tabsSlice from './slices/tabsSlice';
import fileStateSlice from './slices/fileStateSlice';
import { listenerMiddleware } from './listeners/listenerMiddleware';
import { startNewfileListening } from './listeners/fileListeners';

export const store = configureStore({
  reducer: {
    fileStateStore: fileStateSlice,
    headerStore: headerSlice,
    classifiedsStore: classifiedsSlice,
    sectionsStore: sectionsSlice,
    tabsStore: tabsSlice,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

// init File listening event
startNewfileListening();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
